import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import { Card, CardBody } from 'reactstrap';
import requests from '../../requests';
import moment from 'moment';
import swal from 'sweetalert';
import ModalRM from '../UI/Modal';
import helper from '../helper';

export default class Roles extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: false,
            roles: [],
            id: null,
            permissions: [],
            not_more_registers: false,
            assignPermissions: false,
            search_params: { limit: 50, offset: 0 },
            addRole: {
                name: "",
                description: "",
                edit: false,
                permission: []
            }
        }
    }

    deleteRol = async (id) => {
        const { roles } = this.state;
        let selectedUser = false;
        if(id) {
            selectedUser = roles.find(element => element.id === id);
        }

        if(selectedUser) {
            swal({
                title: "Estas seguro?",
                text: "Estas por eliminar un rol de la empresa",
                icon: "warning",
                buttons: true,
                dangerMode: true,
              })
              .then(async (willDelete) => {
                if (willDelete) {

                    let req = new requests({
                        name: 'roles',
                        extraUrl: `/${selectedUser.id}`
                    })
            
                    try {
                        let q = await req.prepareConfig().delete();
                        if(q.Success) {
                            swal('Excelente', 'Rol eliminado correctamente', 'success').then(() => {
                                window.location.reload(window.location.href);
                            })
                        } else {
                            swal('Error', q.Message, 'error').then(() => {
                                window.location.reload(window.location.href);
                            })
                        }
                    } catch (e) {
                        console.error('Error: ', e)
                    }
                } else {
                  swal("Rol no eliminado");
                }
              });

        }
    }

    editRol = async (id) => {
        const { roles } = this.state;
        let selectedRole = false;

        if(id) {
            selectedRole = roles.find(element => element.id === id);
        }

        let addRole = {
            name: selectedRole.name,
            description: selectedRole.description,
            permission: selectedRole.permissions,
            edit: true,
            id
        }


        if(selectedRole) {
            this.setState({ 
                addRole
            })
            this.assignNewUserModal()
        }

    }

    get_roles = async () => {
        this.setState({ loading: true })
        let req = new requests({
            name: 'roles',
            query: this.state.search_params
        })  
        try {
            let q = await req.prepareConfig().get();
            if(q.Data && q.Data.length > 0) {
                let search_params = this.state.search_params;
                let roles = [...this.state.roles,...q.Data];
                search_params.offset = roles.length;
                this.setState({ roles , search_params})
            } else {
                this.setState({ not_more_registers: true});
            }
        } catch (e) {
            console.error('Error: ',e)
        }
        this.setState({ loading: false })
    }

    get_permissions = async () => {
        let req = new requests({
            name: 'permissions'
        })  
        try {
            let q = await req.prepareConfig().get();
            if(q.Data && q.Data.length > 0) {
                this.setState({permissions: q.Data})
            } 
        } catch (e) {
            console.error('Error: ',e)
        }
    }

    componentDidMount = async () => {
        await this.get_permissions();
        await this.get_roles();

        document.title = helper.get_title('Roles')
    }

    assignNewUserModal = async (e = false) => {
        if(e) e.preventDefault();
        this.setState({
            assignPermissions: true
        })
    }

    assignPermissionsToRole = async (e) => {
        e.preventDefault();
        const {  addRole } = this.state;
        if(addRole.name !== ""){
            let req = new requests({
                name: 'roles',
                body: { permissions: addRole.permission }
            })
            if(!addRole.edit) {
                req.body.name = addRole.name;
                req.body.description = addRole.description;
                try {
                    let q = await req.prepareConfig().post();

                    if(q.Success) {
                        swal('Excelente', "Rol agregado exitosamente", 'success').then(() => {
                            this.componentDidMount();
                            this.setState({ 
                                addRole: { name: "", permission: [], edit: false },
                                assignPermissions: false
                            })
                        })
                    } else {
                        swal('Error', q.Message, 'error').then(() => {
                            this.componentDidMount();
                            this.setState({ 
                                addRole: { name: "", permission: [], edit: false },
                                assignPermissions: false
                            })
                        })
                    }

                } catch (e) {
                    console.error('Error: ', e)
                }
            } else {
                req.body.description = addRole.description;
                req.extraUrl = `/${addRole.id}`;
                try {
                    let q = await req.prepareConfig().put();

                    if(q.Success) {
                        swal('Excelente', "Rol modificado exitosamente", 'success').then(() => {
                            this.componentDidMount();
                            this.setState({ 
                                addRole: { name: "", permission: [], edit: false },
                                assignPermissions: false
                            })
                        })
                    } else {
                        swal('Error', q.Message, 'error').then(() => {
                            this.componentDidMount();
                            this.setState({ 
                                addRole: { name: "", permission: [], edit: false },
                                assignPermissions: false
                            })
                        })
                    }

                } catch (e) {
                    console.error('Error: ', e)
                }
            }
        } else {
            swal('Error', "Error en los parametros ingresados", "error");
        }

    }

    crearRol = () => {
        console.log("Crear cuenta")
    }

    addRoleValues = (e) => {
        const { value, id } = e.target;
        let { addRole } = this.state;
        
        addRole[id] = value;
        this.setState({ addRole })
    }

    togglePermission = (e) => {
        let { id } = e.target;
        let { addRole } = this.state;
        id = id.split('|');

        let selectedPerm = addRole.permission.findIndex(e => e.module === id[0] && e.method === id[1]);


        if(selectedPerm >= 0 && addRole.permission.length > 1) {
            addRole.permission.splice(selectedPerm, 1);
        } else if(selectedPerm >= 0 && addRole.permission.length === 1) {
            addRole.permission = [];
        } else if(selectedPerm === -1) {
            addRole.permission.push({ module: id[0], method: id[1] });
        }

        this.setState({ addRole })
    }

    render() {
        const { loading, redirect, roles, addRole, assignPermissions, permissions } = this.state;
        return (
            <Main loading={loading} redirect={redirect} permission={{module: "roles", action: "view"}}>
                <>
                    <SectionTitle 
                        title="Roles"
                        breadcrumb={[{name: "Home", redirect: '/admin/home'}, {name: "Roles"}]}
                        button={[{name: "Agregar rol", icon: "ti-plus", onClick: (e) => this.assignNewUserModal(e), disabled: !helper.checkPermission('roles', 'new')}]}
                    />
                    <div className="row">
                        <div className="col-12">
                            <Card>
                                <div className="card-header bg-dark">
                                    <h4 className="m-b-0 text-white">Roles</h4>
                                </div>

                                {assignPermissions &&
                                    <ModalRM title={`Asignar permisos al rol: ${addRole.name || 'Nuevo'}`} open={true}>
                                        <form onSubmit={this.assignPermissionsToRole}>
                                            <div className="form-body">
                                                <div className="row">
                                                    <div className="col-12">
                                                        <label className="control-label" htmlFor="userMail" >Nombre: *</label>
                                                        <input required type="text" id="name" value={addRole.name} disabled={addRole.edit} className="form-control" onChange={this.addRoleValues}/>
                                                    </div>
                                                    <div className="col-12">
                                                        <label className="control-label" htmlFor="userMail" >Descripcion: </label>
                                                        <input type="text" id="description" value={addRole.description} className="form-control" onChange={this.addRoleValues}/>
                                                    </div>
                                                    <div className="col-12 p-t-20">
                                                        {permissions &&
                                                            <>
                                                            {addRole.permission?.[0] !== 'all' &&
                                                                permissions.map((v, i) => {
                                                                    return (
                                                                        <>
                                                                        <label key={i} className="control-label" htmlFor="userMail">{v.module.toUpperCase()}</label>
                                                                        {v.permissions &&
                                                                            v.permissions.map((val, ind) => {
                                                                                // Buscamos el rol si esta incluido
                                                                                let checked = false;
                                                                                if(addRole.permission.find(e => e.module === v.module && e.method === val.method)){
                                                                                    checked = true;
                                                                                }
                                                                                return (
                                                                                    <div className="form-check" key={Date.now() * ind}>
                                                                                        <input type="checkbox" checked={checked} className="form-check-input" id={`${v.module}|${val.method}`} onChange={this.togglePermission}/>
                                                                                        <label class="form-check-label" htmlFor={`${v.module}|${val.method}`}>{helper.translate_methods(val.method)}</label>
                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }
                                                                        <hr/>
                                                                        </>
                                                                    )
                                                                })

                                                            }
                                                            {addRole.permission?.[0] === 'all' &&
                                                                <div className="alert alert-danger">No se pueden modificar los permisos en este rol</div>

                                                            }
                                                            </>
                                                        }

                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="form-actions">
                                                    {addRole.permission?.[0] !== 'all' &&
                                                        <button type="submit" className="col-4 btn btn-success"> <i className="fa fa-check"></i> Send</button>
                                                    }
                                                    <button type="button" className="col-4 btn btn-info ml-1" onClick={(e) => {e.preventDefault(); this.setState({assignPermissions: false})}}>Cancel</button>
                                                </div>
                                            </div>
                                        </form>
                                    </ModalRM>
                                }

                                <CardBody>
                                    <table className="display nowrap table table-hover table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Rol</th>
                                                <th className="d-none d-md-block">Fecha de alta</th>
                                                <th>Acciones</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {roles &&
                                                roles.map((v) => {
                                                    return (
                                                        <tr key={v.id}>
                                                            <td>{v.name}</td>
                                                            <td className="d-none d-md-block">{moment(v.createdAt).format("DD/MM/YYYY HH:mm")}</td>
                                                            <td>
                                                                {v.name !== 'Administrator' &&
                                                                    <>
                                                                    {helper.checkPermission('roles', 'delete') &&
                                                                        <button className="btn btn-outline-danger btn-xs" onClick={() => this.deleteRol(v.id)}><i className="ti-trash"></i></button>

                                                                    }
                                                                    {helper.checkPermission('roles', 'modify') &&

                                                                        <button className="btn btn-outline-dark btn-xs ml-1" onClick={() => this.editRol(v.id)}><i className="ti-pencil"></i></button>
                                                                    }
                                                                    </>
                                                                }
                                                                {v.name === 'Administrator' &&
                                                                    <>
                                                                        Este rol no se puede modificar ni eliminar
                                                                    </>
                                                                }
        
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <button type="button" className="btn btn-info float-right col-12 col-md-2" onClick={this.get_roles}>Ver Mas</button>
                                    {this.state.not_more_registers &&
                                    <div className="row">
                                        <div className="col-12 col-md-6 alert alert-danger mt-2 mt-md-0">No hay mas registros para mostrar</div>
                                    </div>
                                    }
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </>
            </Main>
        )
    }
}
