import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import swal from 'sweetalert';
import requests from '../../requests';



export default class AddUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            roles: [],
            futils: {},
            loading: false,
            name: null,
            last_name: null,
            dni: null,
            email: null,
            birthday: null,
            phone: null,
            role: null,
            gender: null,
            comuna: null,
            password: null,
            redirect: false
        }
    }

    componentDidMount = async () => {
        this.setState({ loading: true });
        
        try {
            const role = new requests({ name: "roles" });
            const futils_obj = new requests({ name: "futils" });

            const role_q    = await role.prepareConfig().get();
            const futils_q  = await futils_obj.prepareConfig().get();

            let roles   = [];
            let futils = {};

            if(role_q.Success) {
                roles = role_q.Data;
            }
            if(futils_q.Success) {
                futils = futils_q.Data;
            }
            this.setState({ roles, futils, loading: false });
        } catch (e) {
            console.log(e);
            this.setState({ loading: true });
        }

    }

    checkRegEx = (value, type) => {
        if(!value || !type) return false;
        let regEx;
        switch(type){
            case 'text':
                regEx = /^[A-Za-z ]{1,20}$/i;
                break;
            case 'rut':
                regEx = /^([0-9]{1,8})+(-)+([a-z0-9]{1})$/i;
                break;
            case 'number': 
                regEx = /^[0-9]{1,12}$/;
            break;
            case 'email': 
                regEx = /^([A-Za-z0-9._-]{1,20})+@+([a-z]{1,15})+(\.[a-z]{1,4})+(\.[a-z]{1,3})?$/;    
            break;
            default:
                return true;
        }

        let c = new RegExp(regEx)
        if(c.test(value)) return true;
        else return false;
    }

    handleChange = (e) => {
        let { value, id, type, parentNode, name } = e.target;

        if(type === 'select-one'){
            this.setState({ [id]: value })
        } else {
            // has-danger
            if(name && name === "rut") {
                type = "rut";
            } 
            let error = !this.checkRegEx(value, type);
    
            // this.setState({ [id]: value })
            if(error) {
                document.getElementById(id).classList.add('form-control-danger')
                parentNode.classList.add('has-danger');
            } else {
                document.getElementById(id).classList.remove('form-control-danger')
                parentNode.classList.remove('has-danger');
                this.setState({ [id]: value })
            }
        }
    }

    createUser = async (e) => {
        e.preventDefault();

        const requiredValues = ['name', 'last_name', 'dni', 'email', 'role', 'password', 'comuna'];

        let dataSend = {};
        for(let r in this.state) {
            if(r === 'loading') continue;
            if(this.state[r]) {
                dataSend[r] = this.state[r]
            } else if(requiredValues.includes(r)) {
                return swal('Error!', 'Error en los parametros requeridos', 'error');
            }
        }

        let req = new requests({
            name: 'users',
            body: dataSend
        })

        try {
            let q = await req.prepareConfig().post();
            if(q.Success) {
                swal('Excelente', 'Usuario creado exitosamente', 'success').then(() => {
                    this.setState({ redirect: '/admin/users' })
                })
            } else {
                swal('Error', q.Message, 'error')
            }
        } catch (e) {
            console.error('Error: ',e)
        }

    }

    render() {
        const { loading, redirect, futils, roles } = this.state;

        return (
            <Main loading={loading} redirect={redirect} footer='position-fixed' permission={{module: "Usuarios", action: "new"}}>
            
                <SectionTitle 
                    title="Agregar usuario"
                    breadcrumb={[
                        {name: 'Home', redirect: '/'},
                        {name: 'Users', redirect: '/users'},
                        {name: 'Add User'}
                    ]}
                />
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <div className="card-header bg-info">
                                <h4 className="m-b-0 text-white">Nuevo Usuario</h4>
                            </div>
                            <CardBody>
                                <form onSubmit={this.createUser}>
                                    <div className="form-body">
                                        <div className="row p-t-20">
                                            <div className="col-md-4">
                                                <div className="form-group">
                                                    <label className="control-label" htmlFor="name">Nombre: *</label>
                                                    <input required type="text" id="name" className="form-control" placeholder="John" onChange={this.handleChange}/>
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group ">
                                                    <label className="control-label" htmlFor="last_name">Apellido: *</label>
                                                    <input required type="text" id="last_name" className="form-control" placeholder="Doe" onChange={this.handleChange} />
                                                    {/* <small className="form-control-feedback">This is a error</small> */}
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group ">
                                                    <label className="control-label" htmlFor="dni">RUT: *</label>
                                                    <input required type="text" name="rut" id="dni" className="form-control" placeholder="11111111-1" onChange={this.handleChange}/>
                                                    {/* <small className="form-control-feedback">This is a error</small> */}
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="comuna">Comuna: *</label>
                                                <select className="form-control custom-select" id="comuna" onChange={this.handleChange}>
                                                    <option>Selecciona...</option>
                                                    {futils?.comunas?.map((v, i) => {
                                                        return <option key={i} value={v.id}>{v.comuna}</option>
                                                    })
                                                    }
                                                </select>
                                            </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label className="control-label" htmlFor="name">Sexo: *</label>
                                                    <select className="form-control custom-select" id="gender" onChange={this.handleChange}>
                                                        <option>Selecciona...</option>
                                                        {futils?.genders?.map((v, i) => {
                                                            return <option key={i} value={v}>{v}</option>
                                                        })
                                                        }
                                                    </select>
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group ">
                                                    <label className="control-label" htmlFor="password">Password: </label>
                                                    <input type="password" id="password" className="form-control" placeholder="password" onChange={this.handleChange}/>
                                                    {/* <small className="form-control-feedback">This is a error</small> */}
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group ">
                                                    <label className="control-label" htmlFor="phone">Telefono: </label>
                                                    <input type="number" id="phone" className="form-control" placeholder="phone" onChange={this.handleChange} />
                                                    {/* <small className="form-control-feedback">This is a error</small> */}
                                                </div>
                                            </div>
                                            <div className="col-md-4">
                                                <div className="form-group ">
                                                    <label className="control-label" htmlFor="email">Email: *</label>
                                                    <input required type="email" id="email" className="form-control" placeholder="Email" onChange={this.handleChange}/>
                                                    {/* <small className="form-control-feedback">This is a error</small> */}
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label className="control-label" htmlFor="name">Rol: *</label>
                                                        <select className="form-control custom-select" defaultValue='-' id="role" onChange={this.handleChange}>
                                                            <option value="-">Selecciona...</option>
                                                            {roles?.map((v, i) => {
                                                            return <option key={v.id} value={v.id}>{v.name}</option>
                                                            })
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                        </div>
                                        <hr />
                                    </div>
                                    
                                    <div className="form-actions">
                                        <button type="submit" className="col-1 btn btn-success"> <i className="fa fa-check"></i> Save</button>
                                        <button type="button" className="ml-1 btn btn-info">Cancel</button>
   
                                    </div>
                                </form>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Main>
        )
    }
}
