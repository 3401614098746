import React, { useEffect, useState } from 'react';
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import helper from '../helper';

const Products = () => {
    const [loading, setloading]     = useState(false);
    const [redirect, setredirect]   = useState(null);

    const [data, setdata] = useState([]);
    const [not_more_registers, setnot_more_registers] = useState(false);
    const [search_params, set_search_params] = useState({ limit: 50, offset: 0 });

    useEffect(() => {
        document.title = helper.get_title("Productos");

        get_products();
        return () => {
            
        }
    }, [])

    const get_products = async () => {
        setloading(true);
        const req = new requests({ name: 'products', query : search_params});

        try {
            let q = req.prepareConfig();

            q.headers = {};

            q = await q.get();

            if(q.Data && q.Data.length > 0) {
                setdata([...data,...q.Data]);
                set_search_params({ ...search_params, offset: q.Data.length });
            } else {
                setnot_more_registers(true);
            }
            setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    const go_to_product = (id) => {

        if(id) {
            setredirect(`/admin/products/${id}`);
        }
    }

    return (
        <Main loading={loading} redirect={redirect} permission={{module: "products", action: "modify"}}>
            <SectionTitle 
                title="Productos"
                breadcrumb={[
                    {name: "Home", redirect: '/admin'}, 
                    {name: "Productos"}
                ]}
                button={[
                    { 
                        name: "Nuevo producto", 
                        icon: "ti-plus", 
                        disabled: !helper.checkPermission('products', 'new'),
                        onClick: () => { setredirect('/admin/products/new') }
                    }
                ]}
            />

            <div className="row">
                <div className="col-12">
                    <Card>
                        <div className="card-header bg-dark">
                            <h4 className="m-b-0 text-white">Products</h4>
                        </div>
                        <CardBody>
                            <table className="table table-hover table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Código</th>
                                        <th>Articulo</th>
                                        <th>Tipo</th>
                                        <th>Precio</th>
                                        <th>Stock</th>
                                        <th>Requerido</th>
                                        <th>Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(v => {
                                        return (
                                            <tr key={v.id}>
                                                <td>{v.code}</td>
                                                <td>{v.article}</td>
                                                <td>
                                                    {v.type === 'product' &&
                                                        <div className="label label-table label-info">Producto</div>
                                                    }
                                                    {v.type === 'service' &&
                                                        <div className="label label-table label-primary">Servicio</div>

                                                    }
                                                    
                                                </td>
                                                <td>$ {v.price}</td>
                                                <td>{v.stock}</td>
                                                <td>
                                                    {v.availability.required_in_cart &&
                                                        <div className="label label-table label-success">Requerido - {v.availability?.communes?.length || 0} {(v.availability?.communes?.length || 0) > 1 ? "Comunas" :( v.availability?.communes?.length || 0) > 0 ? "Comuna" : ""}</div>
                                                    }
                                                    {!v.availability.required_in_cart &&
                                                        <div className="label label-table label-danger">No Requerido</div>
                                                    }
                                                </td>
                                                <td>
                                                    <button className="btn btn-outline-dark btn-xs" onClick={() => go_to_product(v.id)}><i className="ti-eye"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-info float-right col-12 col-md-2" onClick={get_products}>Ver Mas</button>
                            {not_more_registers &&
                            <div className="row">
                                <div className="col-12 col-md-6 alert alert-danger">No hay mas registros para mostrar</div>
                            </div>
                            }
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default Products;