import React, { Component } from 'react'
import './Layouts.css';
import * as Menu from './Menu.json';
import Topbar from './Topbar';
import { Redirect } from 'react-router-dom';
import * as PackageJSON from '../../../package.json';
import requests from '../../requests';


export default class Sidebar extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Menu: Menu.default,
            redirect: null,
            company: null
        }

    }

    openMenu = (e) => {
        e.preventDefault();
        try {
            let id = e.target.id.split('_')[1];
            if(id) {
                document.getElementById(`li_${id}`).classList.toggle('active');
                document.getElementById(`ul_${id}`).classList.toggle('in');
            }
        } catch (e) { console.log("Err") }
    }

    getSubmenuByPermissions = (submenu) => {
        const { permissions } = requests.getUserData();

        let subMenu = [];
        if(submenu) {
            for(let sm of submenu) {
                if(sm.permission) {
                    // Consultamos si el usuario tiene ese permiso asignado
                    for(let e of permissions) {
                        if(e.module === sm.permission.module) {
                            if(e.action === 'all' && !subMenu.includes(sm)) {
                                subMenu.push(sm)
                            } else if(e.method === sm.permission.method  && !subMenu.includes(sm)) {
                                subMenu.push(sm)
                            }
                        }
                    }
                } else {
                    subMenu.push(sm)
                }
            }
        }

        return subMenu
    }

    componentDidMount = async () => {
        this.setState({ redirect: null })
        document.body.classList.add('skin-default');
        document.body.classList.add('fixed-layout');
    }

    redirect = (e) => {
        e.preventDefault();
        const redirect = e.target.dataset.redirect;
        if(redirect) {
            this.cerrar_menu_mobile()
            this.setState({ redirect });
        }
    }

    cerrar_menu_mobile = () => {
        if(window.screen.width < 900) {
            document.body.classList.remove('show-sidebar');
        }
    }

    render() {
        const { Menu , redirect } = this.state;
        let modulesFiltred = [];
        const { permissions } = requests.getUserData();
        if(!permissions){
            requests.clearData();
            return;
        } 
            
        for(let m of Menu) {
            // analizamos todos los permisos
            if(!m.permission) continue;
            for (let p of permissions) {
                let t = m.permission.find(e => e === p.module);
                if(t && !modulesFiltred.includes(m)) {
                    modulesFiltred.push(m);
                }
            }
        }


        if(redirect) {
            if(redirect === window.location.pathname) {
                this.componentDidMount()
            }
            return (<Redirect to={redirect}/>)
        }

        return (
            <>
                <Topbar update={() => this.componentDidMount()}/>
                <aside className="left-sidebar">
                    <div className="scroll-sidebar">
                        <nav className="sidebar-nav">
                            <ul id="sidebarnav">
                                {Menu &&
                                    Menu.map((m, i) => {
                                        i = Date.now() * 3
                                        return (<>
                                            {m.printEver &&

                                                <li key={i}>
                                                <a  href='/#'
                                                    className={m.submenu && m.submenu.length > 0 ? 'has-arrow waves-effect waves-dark' : 'waves-effect waves-dark'}
                                                    id={`li_${i}`}
                                                    onClick={this.openMenu}
                                                    aria-expanded="false">
                                                        {m.icon &&
                                                            <i className={m.icon}></i>
                                                        }
                                                        <span className="hide-menu" data-redirect={m.href} onClick={this.redirect}>{m.name} 
                                                            {m.stick &&
                                                                <span 
                                                                className={`badge badge-pill ml-auto ${m.stick.class}`}
                                                                >{m.stick.value || 0}</span>
                                                            }
                                                        </span>
                                                </a>
                                                {m.submenu && 
                                                    <ul aria-expanded="false" className="collapse" id={`ul_${i}`}>
                                                    {m.submenu.map((val, ind) => {
                                                        return (<li key={ind}>
                                                            <a href="/#" data-redirect={val.href} onClick={this.redirect}>{val.name}</a>
                                                            </li>)
                                                    })
                                                    }
                                                    </ul>

                                                }
                                                </li>

                                            }
                                        </>)
                                    })
                                }

                                {modulesFiltred &&
                                    modulesFiltred.map((m, i) => {
                                        i = Date.now() * 2
                                        let key = Date.now() * Math.random();
                                        let submenu = this.getSubmenuByPermissions(m.submenu);
                                        return (<>
                                            {m &&
                                                <li key={key}>
                                                    <a  href='/#'
                                                        className={submenu.length > 0 ? 'has-arrow waves-effect waves-dark' : 'waves-effect waves-dark'}
                                                        id={`li_${key}`}
                                                        onClick={this.openMenu}
                                                        aria-expanded="false">
                                                            {m.icon &&
                                                                <i className={m.icon}></i>
                                                            }
                                                            <span className="hide-menu" data-redirect={m.href} onClick={this.redirect}>{m.name} 
                                                                {m.stick &&
                                                                    <span 
                                                                    className={`badge badge-pill ml-auto ${m.stick.class}`}
                                                                    >{m.stick.value || 0}</span>
                                                                }
                                                            </span>
                                                    </a>
                                                    {submenu?.length > 0 && 
                                                        <ul aria-expanded="false" className="collapse" id={`ul_${key}`}>
                                                        {submenu.map((val, ind) => {
                                                            return (<li key={ind}>
                                                                <a href="/#" data-redirect={val.href} onClick={this.redirect}>{val.name}</a>
                                                                </li>)
                                                        })
                                                        }
                                                        </ul>

                                                    }
                                                </li>
                                            }
                                        </>)
                                    })
                                }
                            </ul>
                        </nav>
                    </div>
                    <p className="AppData">developed by <a href={PackageJSON.author.url}>{PackageJSON.author.name}</a> - v{PackageJSON.version}</p>
                </aside>
            </>
        )
    }
}
