import React, { useEffect, useState } from 'react';
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import momentjs from 'moment-timezone';
import helper from '../helper';
import swal from 'sweetalert'

const Turns = () => {
    const [loading, setloading]     = useState(false);
    const [redirect, setredirect]   = useState(null);

    const [futils, setfutils] = useState({});
    const [data, setdata] = useState([]);
    const [not_more_registers, setnot_more_registers] = useState(false);
    const [search_params, set_search_params] = useState({ limit: 50, offset: 0 });

    const buscar = async (e) => {
        const { value } = e.target;

        let s_params = search_params;
        if(value) {
            s_params.email = value;
        } else {
            s_params = { limit: 50, offset: 0 }
        }

        const req = new requests({ name: 'turns', query : s_params});

        try {
            const q = await req.prepareConfig().get();
            let d =  [];
            if(q.Data && q.Data.length > 0) {
                d = q.Data
                set_search_params({ ...s_params, offset: q.Data.length });
                setnot_more_registers(false);
            } else {
                setnot_more_registers(true);
            }
            setdata([...d]);
            set_search_params({ ...s_params, offset: d.length });
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    const get_futils = async () => {
        const req = new requests({ name: 'futils' });
        try {
            const q = await req.prepareConfig().get();
            if(q.Data) {
                setfutils(q.Data);
            } 
            // setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    useEffect(() => {
        document.title = helper.get_title("Turnos");

        get_turns();
        get_futils();
        return () => {
            
        }
    }, [])

    const get_turns = async () => {
        setloading(true);
        const req = new requests({ name: 'turns', query : search_params});

        try {
            const q = await req.prepareConfig().get();
            if(q.Data && q.Data.length > 0) {
                setdata([...data,...q.Data]);
                set_search_params({ ...search_params, offset: q.Data.length });
            } else {
                setnot_more_registers(true);
            }
            setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    const go_to_turn = (id) => {

        if(id) {
            setredirect(`/admin/turns/${id}`);
        }
    }

    return (
        <Main loading={loading} redirect={redirect} permission={{module: "turns", action: "view_matrix"}}>
            <SectionTitle 
                title="Turnos"
                breadcrumb={[
                    {name: "Home", redirect: '/admin'}, 
                    {name: "Turnos"},
                ]}
                button={[]}
            />

            <div className="row">
                <div className="col-12">
                    <Card>
                        <div className="card-header bg-dark">
                            <h4 className="m-b-0 text-white">Turnos</h4>
                        </div>
                        <CardBody>
                            {/* <div className="dataTables_filter mb-2 col-4 d-none d-md-block">
                                <label htmlFor="search" className="col-12">Search: </label>
                                <input id='search' type="text" className="form-control form-control-sm" onChange={buscar}/>
                            </div> */}

                            {data.map(v => {
                                return (
                                    <article key={v.id} className="d-md-none turn-box">
                                        <div className="datos">
                                            <h6>Email:</h6>
                                            <p>{v.email}</p>
                                        </div>
                                        <div className="datos">
                                            <h6>Fecha:</h6>
                                            <p>{momentjs(v.date).tz('Atlantic/Madeira').format("DD/MM/YYYY")} - {v.time}</p>
                                        </div>
                                        <div className="datos">
                                            <h6>Comuna:</h6>
                                            <p>{futils?.comunas?.find(e => e.id === v.comuna)?.comuna || v.comuna}</p>
                                        </div>
                                        <div className="datos">
                                            <h6>Estado:</h6>
                                            <p>
                                            {v.status === 'confirmed' && <div className="label label-table label-success">Confirmado</div>}
                                                    {v.status === 'not_confirmed' && <div className="label label-table label-danger">No confirmado</div>}
                                                    {v.status === 'problem' && <div className="label label-table label-warn">Problema</div>}
                                                    {v.status === 'cancelled' && <div className="label label-table label-danger">Cancelado</div>}
                                                    {v.status === 'completed' && <div className="label label-table label-success">Completado</div>}</p>
                                        </div>
                                        <div className="datos">
                                            <h6>Productos:</h6>
                                            <p>{v.products.length}</p>
                                        </div>
                                        <div className="datos">
                                            <h6>Dirección:</h6>
                                            <p>{v.address}</p>
                                        </div>
                                        <button onClick={() => go_to_turn(v.id)} className="btn btn-primary col-12 mt-1">Ver</button>
                                    </article>
                                )
                            })

                            }

                            <table className="d-none d-md-table table table-hover table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Email</th>
                                        <th>Fecha</th>
                                        <th>Comuna</th>
                                        <th>Estado</th>
                                        <th>Productos</th>
                                        <th>Dirección</th>
                                        <th>Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(v => {
                                        return (
                                            <tr key={v.id}>
                                                <td>{v.email}</td>
                                                <td>{momentjs(v.date).tz('Atlantic/Madeira').format("DD/MM/YYYY")} - {v.time}</td>
                                                <td>{futils?.comunas?.find(e => e.id === v.comuna)?.comuna || v.comuna}</td>
                                                <td>
                                                    {v.status === 'confirmed' && <div className="label label-table label-success">Confirmado</div>}
                                                    {v.status === 'not_confirmed' && <div className="label label-table label-danger">No confirmado</div>}
                                                    {v.status === 'problem' && <div className="label label-table label-warn">Problema</div>}
                                                    {v.status === 'cancelled' && <div className="label label-table label-danger">Cancelado</div>}
                                                    {v.status === 'completed' && <div className="label label-table label-success">Completado</div>}
                                                    
                                                </td>
                                                <td>{v.products.length}</td>
                                                <td>{v.address}</td>
                                                <td>
                                                    <button className="btn btn-outline-dark btn-xs" onClick={() => go_to_turn(v.id)}><i className="ti-eye"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-info float-right col-12 col-md-2" onClick={get_turns}>Ver Mas</button>
                            {not_more_registers &&
                            <div className="row">
                                <div className="col-12 col-md-6 alert alert-danger">No hay mas registros para mostrar</div>
                            </div>
                            }
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default Turns;