import React, { useEffect, useState } from 'react';
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import momentjs from 'moment-timezone';
import helper from '../helper';
import swal from 'sweetalert'

const TurnsModify = ({ match }) => {
    const [loading, setloading]     = useState(false);
    const [redirect, setredirect]   = useState(null);

    const [data, setdata] = useState({});
    const [ id, setId ] = useState(null);

    useEffect(() => {
        document.title = helper.get_title("Turnos");

        const { id } = match.params;
        if(id && id !== 'new') {
            setId(id);
            get_turn(id);
        } else {
            setredirect('/admin/turns');
        }
        return () => {
            
        }
    }, []);


    const get_turn = async (id) => {
        setloading(true);
        const req = new requests({ name: 'turns', extraUrl: `/${id}`});

        try {
            const q = await req.prepareConfig().get();
            if(q.Data ) {
                let data = q.Data[0];
                setdata(data);
            } 
            setloading(false);
        } catch (e) {
            setredirect('/admin/turns');
            console.log(e);
        }
    }


    const cancel = (e) => {
        setredirect('/admin/turns');
    }

    const deleteTurn = (e) => {
        e.preventDefault();
        swal({
            title: "¿Estas seguro?",
            text: "Estas por cancelar un turno. El pago se devolverá al cliente inmediatamente",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                setloading(true);
                let req = new requests({
                    name: "turns",
                    extraUrl: `/${id}`
                })

                try {
                    let q = await req.prepareConfig().delete();
                    setloading(false);

                    if(q.Success) {
                        swal('Excelente', 'Turno eliminado exitosamente', 'success').then(() => {
                            setredirect('/admin/turns');
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e);
                    setredirect('/admin/turns');
                }
            }
        })
    }

    const modify_status = (e) => {
        e.preventDefault();
        swal({
            title: "¿Estas seguro de completar?",
            text: "Estas por completar el turno, el estado cambiará y se le avisara al cliente",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                setloading(true);
                let req = new requests({
                    name: "turns",
                    extraUrl: `/${id}`,
                    body: { status: 'completed' }
                })

                try {
                    let q = await req.prepareConfig().put();
                    setloading(false);

                    if(q.Success) {
                        swal('Excelente', 'Turno modificado exitosamente', 'success').then(() => {
                            window.location.reload();
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e);
                    setredirect('/admin/turns');
                }
            }
        })
    }

    return (
        <Main loading={loading} redirect={redirect} permission={{module: "turns", action: "view"}}>
            <SectionTitle 
                title="Excepciones de turnos"
                breadcrumb={[
                    {name: "Home", redirect: '/admin'}, 
                    {name: "Turnos", redirect: '/admin/turns'},
                    {name: "Detalle de turno"}
                ]}
                button={[]}
            />

            <div className="row">
                <div className="col-12">
                    <Card>
                        <div className="card-header bg-dark">
                            <h4 className="m-b-0 text-white">Detalle de turno. ID: {data.id}</h4>
                        </div>
                        <CardBody>
                            <form>
                                <div className="form-body">
                                    <div className="row p-t-20">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="id_data">ID: </label>
                                                <input type="text" id="id_data" value={data.id} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="email">Email: </label>
                                                <input type="text" id="email" value={data.email} disabled className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="row">
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="id_data">Fecha: </label>
                                                <input type="text" id="id_data" value={momentjs(data.date).tz('Atlantic/Madeira').format('DD/MM/YYYY')} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="time">Hora: </label>
                                                <input type="text" id="time" value={data.time} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="status">Estado: </label>
                                                <input type="text" id="status" value={data.status} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="status">Descripción del estado: </label>
                                                <input type="text" id="status" value={data.status_description} disabled className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                    <div  className="row">
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="comuna">Comuna: </label>
                                                <input type="text" id="comuna" value={data.comuna} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="address">Dirección: </label>
                                                <input type="text" id="address" value={data.address} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="piso">Piso: </label>
                                                <input type="text" id="piso" value={data.piso !== "false" ? data.piso : ""} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="dpto">Dpto: </label>
                                                <input type="text" id="dpto" value={data.dpto !== "false" ? data.piso : ""} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="torre">Torre: </label>
                                                <input type="text" id="torre" value={data.torre !== "false" ? data.piso : ""} disabled className="form-control"/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="extra_description">Datos extras de dirección: </label>
                                                <input type="text" id="extra_description" value={data.extra_address !== "false" ? data.piso : ""} disabled className="form-control"/>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                {/* Productos */}
                                <div className="">
                                    <h4 className="font-weight-bold">Productos</h4>
                                    <table className="display nowrap table table-hover table-striped table-bordered">
                                        <thead>
                                            <tr>
                                                <th>Articulo</th>
                                                <th>Codigo</th>
                                                <th>Precio</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {data?.products?.map(e => {
                                                return (
                                                    <tr key={e.id}>
                                                        <td>{e.article}</td>
                                                        <td>{e.code}</td>
                                                        <td>$ {e.price}</td>
                                                    </tr>
                                                )
                                            })
                                            }
                                        </tbody>
                                    </table>
                                </div>

                                {/* Payment */}
                                {/* <div className="row">
                                    <h4 className="col-12 font-weight-bold">Pago</h4>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <label className="control-label" htmlFor="extra_description">Datos extras de dirección: </label>
                                            <input type="text" id="extra_description" value={data.extra_address !== "false" ? data.piso : ""} disabled className="form-control"/>
                                        </div>
                                    </div> 
                                </div> */}
                            </form>
                            <div className="form-actions">
                                {helper.checkPermission('turns', 'modify_status') &&
                                <button type="button" className="btn btn-success col-12 col-md-3" onClick={modify_status}>Completar</button>
                                }

                                <button type="button" className="ml-md-1 btn btn-info col-12 col-md-3 mt-1 mt-md-0" onClick={cancel}>Cancelar</button>
                                {helper.checkPermission('turns', 'delete') &&
                                <button type="button" className="ml-md-1 btn btn-danger col-12 col-md-3 mt-3 mt-md-0" onClick={deleteTurn}>Cancelar Turno</button>
                                }
                            </div>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default TurnsModify;