import React, { Component } from 'react'

export default class Spinner extends Component {
    render() {
        return (
            <div className="preloader">
                <div className="loader">
                    <div className="loader__figure"></div>
                    <p className="loader__label">Bio Salud</p>
                </div>
            </div>
        )
    }
}
