import React, { Component } from 'react'
import swal from 'sweetalert';
import requests from '../../requests';
import { Redirect } from 'react-router-dom';

export default class ValidateUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            redirect: null
        }
    }

    componentDidMount = async () => {
        let { search } = this.props.location;
        let company = false, t;
        search = search.split('?')

        if(search.length > 1) {
            search = search[1];
            search = search.split('&');

            for(let s of search) {
                s = s.split('=');
                switch(s[0]) {
                    case 't': 
                        t = s[1];
                        break;
                    case 'company':
                        company = true;
                    break;
                    default:
                        break;
                }
            }
        }

        if(t) {
            let req = new requests({
                name: "userVerification",
                extraUrl: `?${company ? 'company&' : ''}t=${t}`
            })

            try {
                let q = await req.prepareConfig().get()
                if(q.Success) {
                    swal('Excelente', `${company ? 'Se te asignó la empresa exitosamente' : 'Usuario verificado exitosamente'}`, 'success').then(() => {
                        sessionStorage.clear()
                        this.setState({ redirect: '/' })
                    })
                } else {
                    swal('Error', `${company ? 'Error al asignar la empresa' : 'Error al verificar el usuario'}`, 'error').then(() => {
                        sessionStorage.clear()
                        this.setState({ redirect: '/' })
                    })
                }
            } catch (e) {
                console.error('Error: ', e)
            }
        }


    }




    render() {
        const { redirect } = this.state;
        if(redirect){
            return <Redirect to={redirect} />
        }
        return (
            <div className="validateUser">
                <h2>Raimon App</h2>
                <hr />
            </div>
        )
    }
}


// https://syscont.com/verificate?t=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI1ZjM1M2VjNDQ4OGVkODFlNzhiZmFhNGEiLCJpYXQiOjE1OTczMjQ5OTYsImV4cCI6MTYyODg2MDk5Nn0.gWeTPtToolVlAkrCseAdOj74OkMIbvN8WUWHyVSeEHk