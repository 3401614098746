import React, { useEffect, useState } from 'react';
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import helper from '../helper';
import swal from 'sweetalert'

const Mtx_turns = () => {
    const [loading, setloading]     = useState(false);
    const [redirect, setredirect]   = useState(null);

    const [data, setdata] = useState([]);

    useEffect(() => {
        document.title = helper.get_title("Turnos");

        get_matrix();
        return () => {
            
        }
    }, [])

    const get_matrix = async () => {
        setloading(true);
        const req = new requests({ name: 'turns', extraUrl: '/available'});

        try {
            const q = await req.prepareConfig().get();
            if(q.Data) {
                setdata(q.Data);
            }
            setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    const toggle_change = (e) => {
        const { name, dataset, value, type } = e.target;
        
        const [ day, row ] = name.split('|');
        const comunaId = parseInt(dataset.comunaid);

        
        if(!day || !row || !comunaId) return;

        let dataToModify = data;

        for(let i = 0; i < dataToModify.length; i++) {
            if(dataToModify[i].comuna_id !== comunaId) continue;

            if(type === 'text') {
                dataToModify[i][day][row] = value;
            } else {
                dataToModify[i][day][row] = parseInt(value);

            }
        }

        setdata([...dataToModify]);
    }

    const save_changes = async () => {
        let dataToModify = [];

        for(let { comuna_id, Lu, Ma, Mi, Ju, Vi, Sa, Do } of data) {
            const temp = {
                comuna: comuna_id,
                Lu,
                Ma,
                Mi,
                Ju,
                Vi,
                Sa,
                Do
            }
            dataToModify.push(temp);
        }

        setloading(true);

        const req = new requests({ name: 'turns', extraUrl: '/available', body: dataToModify });

        try {
            const q = await req.prepareConfig().put();
            setloading(false);
            if(q.Success) {
                swal('Excelente', 'Matriz de turnos modificada correctamente', 'success').then(() => {
                    window.location.reload(window.location.href);
                })
            } else {
                swal('Error', q.Message, 'error').then(() => {
                    window.location.reload(window.location.href);
                })
            }
        } catch(e) {
            console.log('Err');
            setloading(false);
            swal('Error', e.response.Message, 'error').then(() => {
                window.location.reload(window.location.href);
            })
        }
    }

    return (
        <Main loading={loading} redirect={redirect} permission={{module: "turns", action: "view_matrix"}}>
            <SectionTitle 
                title="Matriz de turnos"
                breadcrumb={[
                    {name: "Home", redirect: '/admin'}, 
                    {name: "Turnos"}
                ]}
                button={[
                    {name: "Guardar", icon: "ti-save", onClick: () => { save_changes() }},
                    {name: "Excepciones", icon: "ti-calendar", onClick: () => { setredirect('/admin/exceptions') }}
                ]}
            />

            <div className="row">
                <div className="col-12">
                    <Card>
                        <div className="card-header bg-dark">
                            <h4 className="m-b-0 text-white">Matriz de turnos</h4>
                        </div>
                        <CardBody>
                            <table className="table table-hover table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Comuna</th>
                                        <th>Lunes</th>
                                        <th>Martes</th>
                                        <th>Miercoles</th>
                                        <th>Jueves</th>
                                        <th>Viernes</th>
                                        <th>Sabado</th>
                                        <th>Domingo</th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {data &&
                                        data.map(v => {
                                            return (
                                                <tr key={v.comuna_id}>
                                                    <td>{v.comuna_name}</td>

                                                    <td>
                                                        <div className="turns_matrix">
                                                            <article className="hours">
                                                                <span>
                                                                    <label>AM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Lu|AM" className="form-control form-control-sm" type="number" value={v.Lu.AM}/>
                                                                </span>
                                                                <span className="ml-1">
                                                                    <label>PM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Lu|PM" className="form-control form-control-sm" type="number" value={v.Lu.PM}/>
                                                                </span>
                                                            </article>
                                                            <article className="description mt-2">
                                                                <label>Descripción</label>
                                                                <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Lu|description" className="form-control form-control-sm" type="text" value={v.Lu.description}/>
                                                            </article>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="turns_matrix">
                                                            <article className="hours">
                                                                <span>
                                                                    <label>AM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Ma|AM" className="form-control form-control-sm" type="number" value={v.Ma.AM}/>
                                                                </span>
                                                                <span className="ml-1">
                                                                    <label>PM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Ma|PM" className="form-control form-control-sm" type="number" value={v.Ma.PM}/>
                                                                </span>
                                                            </article>
                                                            <article className="description mt-2">
                                                                <label>Descripción</label>
                                                                <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Ma|description" className="form-control form-control-sm" type="text" value={v.Ma.description}/>
                                                            </article>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="turns_matrix">
                                                            <article className="hours">
                                                                <span>
                                                                    <label>AM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Mi|AM" className="form-control form-control-sm" type="number" value={v.Mi.AM}/>
                                                                </span>
                                                                <span className="ml-1">
                                                                    <label>PM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Mi|PM" className="form-control form-control-sm" type="number" value={v.Mi.PM}/>
                                                                </span>
                                                            </article>
                                                            <article className="description mt-2">
                                                                <label>Descripción</label>
                                                                <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Mi|description" className="form-control form-control-sm" type="text" value={v.Mi.description}/>
                                                            </article>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="turns_matrix">
                                                            <article className="hours">
                                                                <span>
                                                                    <label>AM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Ju|AM" className="form-control form-control-sm" type="number" value={v.Ju.AM}/>
                                                                </span>
                                                                <span className="ml-1">
                                                                    <label>PM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Ju|PM" className="form-control form-control-sm" type="number" value={v.Ju.PM}/>
                                                                </span>
                                                            </article>
                                                            <article className="description mt-2">
                                                                <label>Descripción</label>
                                                                <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Ju|description" className="form-control form-control-sm" type="text" value={v.Ju.description}/>
                                                            </article>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="turns_matrix">
                                                            <article className="hours">
                                                                <span>
                                                                    <label>AM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Vi|AM" className="form-control form-control-sm" type="number" value={v.Vi.AM}/>
                                                                </span>
                                                                <span className="ml-1">
                                                                    <label>PM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Vi|PM" className="form-control form-control-sm" type="number" value={v.Vi.PM}/>
                                                                </span>
                                                            </article>
                                                            <article className="description mt-2">
                                                                <label>Descripción</label>
                                                                <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Vi|description" className="form-control form-control-sm" type="text" value={v.Vi.description}/>
                                                            </article>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="turns_matrix">
                                                            <article className="hours">
                                                                <span>
                                                                    <label>AM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Sa|AM" className="form-control form-control-sm" type="number" value={v.Sa.AM}/>
                                                                </span>
                                                                <span className="ml-1">
                                                                    <label>PM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Sa|PM" className="form-control form-control-sm" type="number" value={v.Sa.PM}/>
                                                                </span>
                                                            </article>
                                                            <article className="description mt-2">
                                                                <label>Descripción</label>
                                                                <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Sa|description" className="form-control form-control-sm" type="text" value={v.Sa.description}/>
                                                            </article>
                                                        </div>
                                                    </td>

                                                    <td>
                                                        <div className="turns_matrix">
                                                            <article className="hours">
                                                                <span>
                                                                    <label>AM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Do|AM" className="form-control form-control-sm" type="number" value={v.Do.AM}/>
                                                                </span>
                                                                <span className="ml-1">
                                                                    <label>PM</label>
                                                                    <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Do|PM" className="form-control form-control-sm" type="number" value={v.Do.PM}/>
                                                                </span>
                                                            </article>
                                                            <article className="description mt-2">
                                                                <label>Descripción</label>
                                                                <input onChange={toggle_change} data-comunaid={v.comuna_id} name="Do|description" className="form-control form-control-sm" type="text" value={v.Do.description}/>
                                                            </article>
                                                        </div>
                                                    </td>


                                                </tr>
                                            )
                                        })
                                    }

                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default Mtx_turns;