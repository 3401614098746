import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import swal from 'sweetalert';
import Switch from '@material-ui/core/Switch';
import helper from '../helper';
export default class ModifyUser extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: null,
            User: null,
            futils: {},
            roles: []
        }
    }

    cancel = (e) => {
        e.preventDefault()
        this.setState({ redirect: '/admin/users' })
    }

    openModal = async (e) => {
        e.preventDefault();
        const { id } = this.props.match.params;
        swal({
            title: "¿Estas seguro?",
            text: "Estas por eliminar un usuario!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                let req = new requests({
                    name: "users",
                    extraUrl: `/${id}`
                })

                try {
                    let q = await req.prepareConfig().delete();
                    if(q.Success) {
                        swal('Excelente', 'Usuario eliminado exitosamente', 'success').then(() => {
                            this.setState({ redirect: '/admin/users' })
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e)
                }
            }
        })
    }

    checkRegEx = (value, type) => {
        if(!value || !type) return false;
        let regEx;
        switch(type){
            case 'text':
                regEx = /^[A-Za-z ]{1,20}$/i;
                break;
            case 'number': 
                regEx = /^[0-9]{1,12}$/;
            break;
            case 'rut':
                regEx = /^([0-9]{1,8})+(-)+([a-z0-9]{1})$/i;
            break;
            case 'email': 
                regEx = /^([A-Za-z0-9._-]{1,20})+@+([a-z]{1,15})+(\.[a-z]{1,4})+(\.[a-z]{1,3})?$/;    
            break;
            default:
                return true;
        }

        let c = new RegExp(regEx)
        if(c.test(value)) return true;
        else return false;
    }

    handleChange = (e) => {
        let { value, id, type, parentNode, name } = e.target;
        let { User } = this.state;

        if(type === 'select-one'){
            User[id] = value;
            this.setState({ User })
        } else if(type === 'checkbox') {
            let [ name_field, comuna ] = id.split('|');
            if(name_field !== 'comuna') return;
            comuna = parseInt(comuna);

            if(!User[name_field]) {
                User[name_field]= [];
            }

            if(User[name_field].includes(comuna)) {
                // Eliminamos la comuna
                User[name_field] = User[name_field].filter(e => e !== comuna);
            } else {
                // La agregamos
                User[name_field].push(comuna);
            }

            this.setState({ User });
        } else {
            // has-danger
            if(name && name === "rut") {
                type = "rut"
            }
            let error = !this.checkRegEx(value, type);

            // this.setState({ [id]: value })
            if(error) {
                document.getElementById(id).classList.add('form-control-danger')
                parentNode.classList.add('has-danger');
            } else {
                document.getElementById(id).classList.remove('form-control-danger')
                parentNode.classList.remove('has-danger');
                User[id] = value;
                this.setState({ User })
            }
        }
    }

    select_all_communes = (e) => {
        e.preventDefault();
        let { futils, User } = this.state;

        if(!futils.comunas) return;
        User['comuna'] = futils.comunas.map(e => e.id);

        this.setState({ User });
    }


    componentDidMount = async () => {

        this.setState({ loading: true });
        
        try {
            const role = new requests({ name: "roles" });
            const futils_obj = new requests({ name: "futils" });

            const role_q    = await role.prepareConfig().get();
            const futils_q  = await futils_obj.prepareConfig().get();

            let roles   = [];
            let futils = {};

            if(role_q.Success) {
                roles = role_q.Data;
            }
            if(futils_q.Success) {
                futils = futils_q.Data;
            }
            this.setState({ roles, futils, loading: false });
        } catch (e) {
            console.log(e);
            this.setState({ loading: true });
        }

        const { id } = this.props.match.params;
        if(id) {
            // Hacemos el request
            let req = new requests({
                name: "users",
                extraUrl: `/${id}`
            })

            try {
                let q = await req.prepareConfig().get();
                if(q.Data.length === 0) {
                    swal('Error', "Error al modificar usuario", 'error').then(() => {
                        this.setState({ redirect: '/admin/users' })
                    })
                } else {
                    let user = q.Data[0]

                    console.log(user);
                    this.setState({
                        loading: false,
                        User: {
                            name: user.name,
                            last_name: user.last_name,
                            email: user.email,
                            gender: user.gender || '-',
                            dni: user.dni,
                            phone: user.phone || '',
                            role: this.state.roles.find(e => e.name === user.role)?.id || user.role,
                            comuna: user.comuna.map(e => e.id) || [],
                            active: user.active || '',
                            verified: user.verified,
                        }
                    })
                }
            } catch (e) {
                console.error(e)
            }
        } else {
            this.setState({redirect: '/admin/users'})

        }

    }

    modify = async (e) => {
        this.setState({ loading: true })
        e.preventDefault();
        const { id } = this.props.match.params;
        let sendData = {}
        for(let s in this.state.User) {
            if(s === 'email' || s === 'dni' || s === 'verified' || s === 'active') continue;
            if(this.state.User[s] === '-' || this.state.User[s] === '') continue;

            sendData[s] = this.state.User[s];
        }

        let req = new requests({
            name: 'users',
            extraUrl: `/${id}`,
            body: sendData
        })

        try {
            let q = await req.prepareConfig().put();
            this.setState({ loading: false })
            if(q.Success) {
                swal('Excelente', 'Usuario modificado exitosamente', 'success').then(() => {
                    this.setState({ redirect: '/admin/users' })
                })
            } else {
                swal('Error', q.Message, 'error')
            }
        } catch (e) {
            console.error('Error: ',e)
        }
    }

    toggleActive = async (e) => {
        const { id } = this.props.match.params;

        const { checked } = e.target;

        let req = new requests({
            name: 'users',
            extraUrl: `/${id}`,
            body: {active: checked }
        })

        try {
            let q = await req.prepareConfig().put();
            if(q.Success) {
                this.componentDidMount()
            } else {
                swal('Error', 'Error al cambiar el estado del usuario', 'error').then(() => {
                    this.componentDidMount();
                })
            }
        } catch(e) {
            console.error('Error: ', e)
        }
    }

    render() {
        const { loading, User, redirect, futils, roles } = this.state;
        return (
            <Main loading={loading} redirect={redirect} footer='position-fixed' permission={{module: "Usuarios", action: "edit"}}>
                <SectionTitle 
                    title="Modificar usuario"
                    breadcrumb={[
                        {name: 'Home', redirect: '/'},
                        {name: 'Users', redirect: '/admin/users'},
                        {name: 'Modify User'}
                    ]}
                />
                <div className="row">
                    <div className="col-12">
                        {User &&
                            <>
                                <Card>
                                    <div className="card-header bg-info">
                                    <h4 className="m-b-0 text-white">{User.name} {User.last_name} - {User.role}</h4>
                                    </div>
                                    <CardBody>
                                        <form onSubmit={this.modify}>
                                            <div className="form-body">
                                                <div className="row p-t-20">
                                                    <div className="offset-md-11 col-12 col-md-1">
                                                        <h6>Activo</h6>
                                                        <Switch
                                                            disabled={!helper.checkPermission('users', 'modify')}
                                                            checked={User.active}
                                                            onChange={this.toggleActive}
                                                            color="primary"
                                                            name="activeUser"
                                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                                        />
                                                    </div>

                                                </div>

                                                <div className="row p-t-20">
                                                    <div className="col-md-4">
                                                        <div className="form-group">
                                                            <label className="control-label" htmlFor="name">Nombre: *</label>
                                                            <input required type="text" id="name" className="form-control" defaultValue={User.name} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="lastname">Apellido: *</label>
                                                            <input required type="text" id="last_name" className="form-control" defaultValue={User.last_name} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="dni">RUT: *</label>
                                                            <input disabled className="form-control" defaultValue={User.dni} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="control-label" htmlFor="name">Gender:</label>
                                                            <select className="form-control custom-select" defaultValue={User.gender} id="gender" onChange={this.handleChange}>
                                                                <option value="-">Selecciona...</option>
                                                                {futils?.genders?.map((v, i) => {
                                                                    return <option key={i} value={v}>{v}</option>
                                                                })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>

                                                    <div className="col-md-2">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="password">Password: </label>
                                                            <input type="password" id="password" className="form-control" placeholder="password" onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="phone">Phone: </label>
                                                            <input type="number" id="phone" className="form-control" defaultValue={User.phone} onChange={this.handleChange}/>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-4">
                                                        <div className="form-group ">
                                                            <label className="control-label" htmlFor="email">Email: *</label>
                                                            <input disabled className="form-control" defaultValue={User.email} />
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label className="control-label" htmlFor="name">Role: *</label>
                                                            <select className="form-control custom-select" defaultValue={User.role} onChange={this.handleChange} id='role'>
                                                                <option value="-">Selecciona...</option>
                                                                {roles?.map((v, i) => {
                                                                    return <option key={v.id} value={v.id}>{v.name}</option>
                                                                    })
                                                                }
                                                            </select>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="row mb-5">
                                                    <h4 className="col-2 text-uppercase font-weight-bold">Comunas</h4>
                                                    <div className="form-group col-2">
                                                        <button type="button" onClick={this.select_all_communes} className="btn btn-dark">Seleccionar todas las comunas</button>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="row">
                                                            {futils &&
                                                                futils?.comunas?.map(v => {
                                                                    return (
                                                                        <div className="col-2" key={v.id}>
                                                                            <div className="form-check">
                                                                                <input type="checkbox" onClick={this.handleChange} checked={User?.comuna?.includes(v.id)} id={`comuna|${v.id}`} className="form-check-input"/>
                                                                                <label className="form-check-label" htmlFor={`comuna|${v.id}`}>{v.comuna}</label>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="form-actions">
                                                {helper.checkPermission('users', 'modify') &&
                                                    <button type="submit" className="col-12 col-md-3 btn btn-success"> <i className="fa fa-save"></i> Guardar</button>
                                                }
                                                {helper.checkPermission('users', 'delete') &&
                                                    <button type="button" className="ml-md-1 mt-1 mt-md-0 col-12 col-md-3 btn btn-danger" onClick={this.openModal}>Eliminar</button>

                                                }
                                                <button type="button" className="ml-md-1 mt-1 mt-md-0 col-12 col-md-3 btn btn-info" onClick={this.cancel}>Cancelar</button>

                                            </div>
                                        </form>
                                    </CardBody>

                                </Card>
                                {/* <Card>
                                    <div className="card-header bg-info">
                                        <h4 className="m-b-0 text-white">Compañias Asignadas ({companies?.length})</h4>
                                    </div>
                                    <CardBody>
                                        <table className="display nowrap table table-hover table-striped table-bordered">
                                            <thead>
                                                <th>CUIT</th>
                                                <th>Razon Social</th>
                                                <th>Rol</th>
                                                <th>Permisos asignados</th>
                                            </thead>
                                            <tbody>
                                                {companies &&
                                                    companies.map(v => {
                                                        return (
                                                            <tr key={Date.now()}>
                                                                <td>{v.cuit}</td>
                                                                <td>{v.name}</td>
                                                                <td><div className="label label-table label-info">{v.role}</div></td>
                                                                <td>{v.modulesPermited.length}</td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card> */}
                            </>
                        }
                    </div>
                </div>
            </Main>
        )
    }
}
