export default {
    checkPermission: (module, method) => {
        const udata = JSON.parse(localStorage.getItem('userData'));
        let modulesPermited = []
        if(udata) {
            modulesPermited = udata.permissions || [];
        }

        if(module && method && modulesPermited) {
            let check = modulesPermited.find(element => element.module === module);
            if(!check) {
                return false;
            } else if(check.method !== 'all') {
                check = modulesPermited.find(element => element.module === module && element.method === method);
                return !!check
            }
        }
    },
    translate_methods: function(word) {
        let word_return = word;
        switch(word) {
            case 'new': 
                word_return = "Puede agregar";
                break;
            case 'modify': 
                word_return = "Puede modificar";

            break;
            case 'modify_status': 
                word_return = "Puede modificar el estado";

            break;
            case 'delete': 
                word_return = "Puede eliminar";

            break;
            case 'get':
            case 'view':
                word_return = "Puede ver";

                break;
            case 'download':
                word_return = "Puede descargar";

                break;
            case 'view_matrix':
                word_return = "Puede ver la matriz de turnos";

                break;
            case 'modify_matrix':
                word_return = "Puede modificar la matriz de turnos";

                break;
            default:
                break;
        }
        return word_return;
    },
    zFill(id, length, ifId = false) {
        // Si es ID solo tomamos como id el ultimo tramo
        id = id.toString();
        let prevId = id.split('.');
        if(ifId) {
            id = prevId.splice(prevId.length - 1, 1);
        }
        let idLength = id.length;
        let dataReturn = false;

        if(idLength <= length) {
            dataReturn = "";

            if(ifId) {
                for(let m of prevId) {
                    dataReturn += m + "."
                }
            }

            let addZero = length - idLength;

            while(addZero > 0){
                dataReturn += "0";
                addZero--;
            }

            dataReturn += id;
        } 

        return dataReturn;
    },
    get_title: (section) => `${section} | Bio Salud`
}