import Home from "../Admin/Home/Home";

// // Users
import Users from "../Admin/Users/Users";
// import MyProfile from "../Home/MyProfile";
import AddUser from "../Admin/Users/AddUser";
import ModifyUser from "../Admin/Users/ModifyUser";
// import Companies from "../Companies/Companies";
// import AddCompany from "../Companies/AddCompany";
// import ModifyCompany from "../Companies/ModifyCompany";
import Roles from '../Admin/Roles/Roles';
import Mtx_turns from '../Admin/Turns/index';
import Exceptions from '../Admin/Turns/exceptions';
import ExceptionsModify from '../Admin/Turns/ExceptionsModify';
import Turns from '../Admin/Turns/Turns';
import TurnsModify from '../Admin/Turns/TurnsModify';
import Products from '../Admin/Products/Products';
import ProductsModify from '../Admin/Products/ProductsModify';
import Certs from "../Admin/Certs/Cert";
import HomePage from '../Page/Home.js';
import Turn from "../Page/Turn";
// import MiEmpresa from '../Usuarios y Roles/MiEmpresa';
// import Rubros from '../Configuracion/Rubros';
// import Cuentas from '../Configuracion/Cuentas';
// import Ejercicios from '../Configuracion/Ejercicios';
// import UsersbyCompanies from "../Usuarios y Roles/UsersbyCompanies";

const MainRoutes = [
    {path: '/', name: 'HomePage', component: HomePage},
    {path: '/turns', name: 'TurnsHome', component: Turn},
    {path: '/admin/home', name: 'Home', component: Home},
    {path: '/admin/users', name: 'Users', component: Users},
    {path: '/admin/users/new', name: 'Users', component: AddUser},
    {path: '/admin/users/:id', name: 'Users', component: ModifyUser},
    {path: '/admin/roles', name: 'Roles', component: Roles},
    {path: '/admin/available-turns', name: 'Mtx_turns', component: Mtx_turns},
    {path: '/admin/exceptions', name: 'Exceptions', component: Exceptions},
    {path: '/admin/exceptions/:id', name: 'Exceptions', component: ExceptionsModify},
    {path: '/admin/turns', name: 'Turns', component: Turns},
    {path: '/admin/turns/:id', name: 'Turns', component: TurnsModify},
    {path: '/admin/products', name: 'Products', component: Products},
    {path: '/admin/products/:id', name: 'Products', component: ProductsModify},
    {path: '/admin/certs', name: 'Certificados', component: Certs}
]

const indexRoutes = [
    ...MainRoutes
];

export default indexRoutes;