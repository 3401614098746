import React, { useEffect, useState } from 'react';
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import momentjs from 'moment-timezone';
import helper from '../helper';
import swal from 'sweetalert'
import ModalRM from '../UI/Modal';

const Certs = () => {
    const [loading, setloading]     = useState(false);
    const [redirect, setredirect]   = useState(null);
    const [modal_new, setmodal_new] = useState(false);

    const [data, setdata] = useState([]);
    const [not_more_registers, setnot_more_registers] = useState(false);
    const [search_params, set_search_params] = useState({ limit: 50, offset: 0 });

    useEffect(() => {
        document.title = helper.get_title("Certificados");
        get_products();
        return () => {
            
        }
    }, [])

    const subir_archivo = (e) => {
        const { files } = e.target;
        if(!files || files.length === 0) {
            swal('Error', 'Debe subir un archivo', 'error');
            return
        }
        const file = files[0];

        if(file.type !== 'text/csv' && file.type !== 'application/vnd.ms-excel') {
            swal('Error', 'El archivo solo puede ser CSV', 'error');
            return
        }

        const data_send = new FormData();

        data_send.append('file', file);

        swal({
            title: "¿Estas seguro?",
            text: "Estas por subir un nuevo archivo de certificados",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                setloading(true);
                let req = new requests({
                    name: "certs",
                    form: data_send,
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                })

                try {
                    let q = await req.prepareConfig().post();
                    setloading(false);

                    if(q.Success) {
                        swal('Excelente', 'Archivo subido exitosamente', 'success').then(() => {
                            window.location.reload();
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e);
                    window.location.reload();
                }
            }
        })
    }

    const get_products = async () => {
        setloading(true);
        const req = new requests({ name: 'certs', extraUrl: '/files', query : search_params});

        try {
            const q = await req.prepareConfig().get();
            if(q.Data && q.Data.length > 0) {
                setdata([...data,...q.Data]);
                set_search_params({ ...search_params, offset: q.Data.length });
            } else {
                setnot_more_registers(true);
            }
            setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    return (
        <Main loading={loading} redirect={redirect} permission={{module: "certificates", action: "view"}}>
            <SectionTitle 
                title="Certificados"
                breadcrumb={[
                    {name: "Home", redirect: '/admin'}, 
                    {name: "Certificados"}
                ]}
                button={[
                    { 
                        disabled: !helper.checkPermission('certificates', 'new'),
                        name: "Nuevo archivo", 
                        icon: "ti-plus", 
                        onClick: () => { setmodal_new(true); }
                    }
                ]}
            />

            <div className="row">
                <div className="col-12">
                    <Card>
                        <div className="card-header bg-dark">
                            <h4 className="m-b-0 text-white">Products</h4>
                        </div>

                        {modal_new &&
                            <ModalRM title="Subir nuevo archivo" open={true}>
                                <form>
                                    <div className="row">
                                        <div className="col-12">
                                            <div className="form-group">
                                                <label className="btn btn-dark" htmlFor="file">Subir Archivo</label>
                                                <input className="d-none" type="file" name="file" id="file" onChange={subir_archivo} />
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </ModalRM>
                        }


                        <CardBody>
                            <table className="table table-hover table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Nombre</th>
                                        <th>Fecha de subida</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(v => {
                                        return (
                                            <tr key={v._id}>
                                                <td>{v._id}</td>
                                                <td>{v.name}</td>
                                                <td>{momentjs(v.createdAt).tz('America/Argentina/Buenos_Aires').format("DD/MM/YYYY hh:mm:ss")}</td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-info float-right col-12 col-md-2" onClick={get_products}>Ver Mas</button>
                            {not_more_registers &&
                            <div className="row">
                                <div className="col-12 col-md-6 alert alert-danger">No hay mas registros para mostrar</div>
                            </div>
                            }
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default Certs;