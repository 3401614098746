import React, { Component } from 'react'
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import helper from '../helper';
import requests from '../../requests';
import momentjs from 'moment-timezone';


export default class Home extends Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: false,
            redirect: null,
            turns: [],
            futils: {}
        }
    }

    get_turns = async () => {
        // Obtenemos la comuna del usuario
        const { comuna } = await requests.getUserData();
        if(!comuna) return;

        this.setState({ loading: true });

        let comunas = "";
        comunas += comuna.map(e => comunas === "" ? e : `,${e}`);

        const req = new requests({ name: 'turns', query: { comunas } });

        try {
            const q = await req.prepareConfig().get();
            let turns = [];
            if(q.Data) {
                turns = q.Data;
            }
            console.log(turns)
            this.setState({ loading: false, turns });
        } catch (e) {
            console.log("Err: ", e);
            await requests.clearData();
        }

    }

    componentDidMount() {
        document.title = helper.get_title('Home');

        this.get_turns();
    }

    get_futils = async () => {
        const req = new requests({ name: 'futils' });
        try {
            const q = await req.prepareConfig().get();
            if(q.Data) {
                this.setState({ futils: q.Data});
            } 
            // setloading(false);
        } catch (e) {
            this.setState({redirect: '/admin'});
            console.log(e);
        }
    }

    go_to_turn = (id) => {
        if(id) {
            this.setState({ redirect: `/admin/turns/${id}` });
        }
    }


    render() {
        const { loading, redirect, futils } = this.state;
        return (
            <Main loading={loading} redirect={redirect} footer='position-fixed'>
                <>
                <SectionTitle 
                    title="Dashboard"
                    breadcrumb={[{name: "Home"}]}
                />
                <div className="row">
                    <div className="col-12">
                        <Card>
                            <CardBody>

                                {this.state.turns.map(v => {
                                    return (
                                        <article key={v.id} className="d-md-none turn-box">
                                            <div className="datos">
                                                <h6>Email:</h6>
                                                <p>{v.email}</p>
                                            </div>
                                            <div className="datos">
                                                <h6>Fecha:</h6>
                                                <p>{momentjs(v.date).tz('Atlantic/Madeira').format("DD/MM/YYYY")} - {v.time}</p>
                                            </div>
                                            <div className="datos">
                                                <h6>Comuna:</h6>
                                                <p>{futils?.comunas?.find(e => e.id === v.comuna)?.comuna || v.comuna}</p>
                                            </div>
                                            <div className="datos">
                                                <h6>Estado:</h6>
                                                <p>
                                                {v.status === 'confirmed' && <div className="label label-table label-success">Confirmado</div>}
                                                        {v.status === 'not_confirmed' && <div className="label label-table label-danger">No confirmado</div>}
                                                        {v.status === 'problem' && <div className="label label-table label-warn">Problema</div>}
                                                        {v.status === 'cancelled' && <div className="label label-table label-danger">Cancelado</div>}
                                                        {v.status === 'completed' && <div className="label label-table label-success">Completado</div>}</p>
                                            </div>
                                            <div className="datos">
                                                <h6>Productos:</h6>
                                                <p>{v.products.length}</p>
                                            </div>
                                            <div className="datos">
                                                <h6>Dirección:</h6>
                                                <p>{v.address}</p>
                                            </div>
                                            <button onClick={() => this.go_to_turn(v.id)} className="btn btn-primary col-12 mt-1">Ver</button>
                                        </article>
                                    )
                                })
                                    
                                }


                                <table className="d-none d-md-table display nowrap table table-hover table-striped table-bordered">
                                    <thead>
                                        <tr>
                                            <th>Email</th>
                                            <th>Fecha</th>
                                            <th>Comuna</th>
                                            <th>Estado</th>
                                            <th>Productos</th>
                                            <th>Dirección</th>
                                            <th>Ver</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {this.state.turns.map(v => {
                                            return (
                                                <tr key={v.id}>
                                                    <td>{v.email}</td>
                                                    <td>{momentjs(v.date).tz('Atlantic/Madeira').format("DD/MM/YYYY")} - {v.time}</td>
                                                    <td>{futils?.comunas?.find(e => e.id === v.comuna)?.comuna || v.comuna}</td>
                                                    <td>
                                                        {v.status === 'confirmed' && <div className="label label-table label-success">Confirmado</div>}
                                                        {v.status === 'not_confirmed' && <div className="label label-table label-danger">No confirmado</div>}
                                                        {v.status === 'problem' && <div className="label label-table label-warn">Problema</div>}
                                                        {v.status === 'cancelled' && <div className="label label-table label-danger">Cancelado</div>}
                                                        {v.status === 'completed' && <div className="label label-table label-success">Completado</div>}
                                                        
                                                    </td>
                                                    <td>{v.products.length}</td>
                                                    <td>{v.address}</td>
                                                    <td>
                                                        <button type="button" className="btn btn-outline-dark btn-xs" onClick={() => this.go_to_turn(v.id)}><i className="ti-eye"></i></button>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                        }
                                    </tbody>
                                </table>
                            </CardBody>
                        </Card>
                    </div>
                </div>
                </>
            </Main>
        )
    }
}