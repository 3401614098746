import React, { Component } from 'react';
import './Layouts.css';

// import LogoText from "../../assets/images/logo-text.png";
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import requests from '../../requests';

export default class Topbar extends Component {
    constructor(props) {
        super(props);

        this.state = {
            User: {
                name: null,
                lastName: null,
                image: null
            },
            comapnies: [],
            companySelected: null,
            redirect: null,
            notVerifyUser: false,
            miniSBar: false
        }
    }


    miniSidebar = (e) => {
        e.preventDefault();
        document.body.classList.toggle('mini-sidebar');
        this.setState({ miniSBar: !this.state.miniSBar });
    }

    changeColor = (e) => {
        e.preventDefault();
        document.body.classList.toggle('skin-blue-dark');
        document.body.classList.toggle('skin-blue');
    }

    openMenu = (e) => {
        e.preventDefault();
        document.getElementById('userMenu').classList.toggle('show');
    }

    componentDidMount = async () => {
        const userData = requests.getUserData();
        const User = {
            name: userData.name,
            lastName: userData.lastName,
            image: "" || "http://nzjainsangh.org/Content/images/noimage.jpg"
        }

        const window_width  = window.screen.width;

        if(window_width < 900) {
            document.body.classList.add('mini-sidebar');
            this.setState({ miniSBar: true });
        }

        window.addEventListener('resize',() => {
            document.body.classList.add('mini-sidebar');
            this.setState({ miniSBar: true });
        })
        
        

        this.setState({ User, redirect: null });
    }

    redirect = (e) => {
        e.preventDefault();
        const redirect = e.target.dataset.redirect;
        if(redirect) {
            this.setState({ redirect });
        }
    }

    logout = () => {
        requests.clearData();
    }

    openMiniMenu = (e) => {
        e.preventDefault();
        document.body.classList.toggle('show-sidebar');
    }

    render() {
        const { User, redirect, miniSBar } = this.state;
        
        

        if(redirect) {
            if(redirect === window.location.pathname) {
                // window.location.reload(window.location.href);
                this.componentDidMount()
            } else {
                return (<Redirect to={redirect} />)
            }
        } 

        return (
            <header className="topbar">
                <nav className="navbar top-navbar navbar-expand-md navbar-dark">
                {/* LOGO */}
                    <div className="navbar-header">
                        {!miniSBar &&
                            <img width="200" src={require('../../assets/icons/logo.png')} alt="Icono"/>
                        }
                        {/* {miniSBar &&
                            <img width="200" src={require('../../assets/icons/logo_mini.png')} alt="Icono"/>
                        } */}
                    </div>

                    {/* {!notVerifyUser &&
                        <div className="notVerifyUser">
                            <h1>Usuario no verificado</h1>
                            <hr />
                            <h3>Por favor, ¡confirme su cuenta desde su email registrado!</h3>
                            <hr />
                            <button className="btn btn-danger" onClick={(e) => {e.preventDefault(); this.logout();}}>Salir</button>
                        </div>
                    } */}

                    <div className="navbar-collapse">
                        <ul className="navbar-nav mr-auto">
                            <li className="nav-item"> 
                                <a href="/#" onClick={this.openMiniMenu} className="nav-link nav-toggler d-block d-md-none waves-effect waves-dark" 
                                    >
                                    <i className="ti-menu"></i>
                                </a> 
                            </li>
                            <li className="nav-item"> 
                                <a href="/#" className="nav-link sidebartoggler d-none d-lg-block d-md-block waves-effect waves-dark"  onClick={this.miniSidebar}>
                                    <i className="icon-menu"></i>
                                </a> 
                            </li>
                        </ul>

                        <ul className="navbar-nav my-lg-0">
                            <li className="nav-item dropdown u-pro">
                                <a  className="nav-link dropdown-toggle waves-effect waves-dark profile-pic" 
                                    onClick={this.openMenu}
                                    href="/#"
                                    data-toggle="dropdown" 
                                    aria-haspopup="true"    
                                    aria-expanded="false">
                                        <img src={User.image} alt="user" className="" /> 
                                        <span className="hidden-md-down"> {User.name} {User.lastName || ''} &nbsp;
                                            <i className="fa fa-angle-down"></i>
                                        </span>
                                </a>
                                <div id="userMenu" className="dropdown-menu dropdown-menu-right animated flipInY">
                                    
                                    {/* <a href="/#" data-redirect="/myprofile"
                                    onClick={this.redirect}
                                    className="dropdown-item"><i className="ti-user"></i> My Profile</a> */}
                                    
                                    {/* <div className="dropdown-divider"></div> */}
                                    
                                    {/* <a href="/#"
                                    onClick={this.redirect}
                                    className="dropdown-item"><i className="ti-settings"></i> Account Setting</a> */}

                                    <a href="/#"
                                    className="dropdown-item" onClick={this.changeColor}>
                                        <i className="ti-spray"></i> Change Theme
                                    </a>
                                    
                                    <a href="/#"
                                    onClick={this.logout}
                                    className="dropdown-item"><i className="fa fa-power-off"></i> Logout</a>
                                    
                                </div>
                            </li>
                        </ul>
                    </div>

                </nav>
            </header>
        )
    }
}
