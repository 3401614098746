import React, { useEffect, useState } from 'react';
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import momentjs from 'moment-timezone';
import helper from '../helper';
import swal from 'sweetalert'

const ExceptionsModify = ({ match }) => {
    const [loading, setloading]     = useState(false);
    const [redirect, setredirect]   = useState(null);

    const [futils, setfutils] = useState({});
    const [data, setdata] = useState({});
    const [ id, setId ] = useState(null);

    useEffect(() => {
        document.title = helper.get_title("Excepciones");

        const { id } = match.params;
        if(id && id !== 'new') {
            setId(id);
            get_exception(id);
        } else {
            const new_data = {
                date_start: Date.now(),
                date_end: Date.now(),
                Lu: { AM: 0, PM: 0, description: 0 },
                Ma: { AM: 0, PM: 0, description: 0 },
                Mi: { AM: 0, PM: 0, description: 0 },
                Ju: { AM: 0, PM: 0, description: 0 },
                Vi: { AM: 0, PM: 0, description: 0 },
                Sa: { AM: 0, PM: 0, description: 0 },
                Do: { AM: 0, PM: 0, description: 0 }
            }
            setdata(new_data);
        }
        get_futils();
        return () => {
            
        }
    }, []);

    const get_futils = async () => {
        const req = new requests({ name: 'futils' });
        try {
            const q = await req.prepareConfig().get();
            if(q.Data) {
                setfutils(q.Data);
            } 
            // setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }


    const get_exception = async (id) => {
        setloading(true);
        const req = new requests({ name: 'turns', extraUrl: `/exceptions/${id}`});

        try {
            const q = await req.prepareConfig().get();
            if(q.Data ) {
                let data = q.Data[0];
                data.comunas = data.comunas.map(c => c.id);
                setdata(data);
            } 
            setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    const handle_change = (e) => {
        const { value, name, id, type, checked } = e.target;

        const [ field, row ] = id.split('|');

        let data_to_push = data;
        
        if(row) {
            if(field === 'comuna') {
                // Sumamos una comuna
                if(!data_to_push.comunas) {
                    data_to_push.comunas = [];
                }
                if(checked) {
                    // Agregamos al array
                    data_to_push.comunas.push(parseInt(row));
                } else {
                    // Eliminamos del array
                    if(data_to_push.comunas.length > 1) {
                        data_to_push.comunas = data_to_push.comunas.filter(e => e !== parseInt(row));
                    } else {
                        data_to_push.comunas = [];
                    }
                }
            } else {
                data_to_push[row][field] = type === 'number' ? parseInt(value) : value;
            }
        } else {
            // Manejamos las fechas
            const [ year, month, day ] = value.split('-');

            const date = Date.UTC(year, month, day, 0 , 0 , 0 );

            data_to_push[field] = date;
        }

        setdata({ ...data_to_push });
    }

    const send_exc = async (e) => {
        e.preventDefault();
        setloading(true);
        let req = new requests({ name: 'turns', body: data, extraUrl: '/exceptions' });
        try {
            if(id) {
                // Editamos
                req.extraUrl = `/exceptions/${id}`;

                const q = await req.prepareConfig().put();
                if(q.Success) {
                    swal('Excelente', 'Registro modificado correctamente', 'success').then(() => {
                        setredirect('/admin/exceptions');
                    })
                } else {
                    swal('Error', 'Error al modificar el registro', 'error').then(() => {
                        setredirect('/admin/exceptions');
                    })
                }

            } else {
                // Agregamos nuevo
                const q = await req.prepareConfig().post();
                if(q.Success) {
                    swal('Excelente', 'Registro agregado correctamente', 'success').then(() => {
                        setredirect('/admin/exceptions');
                    })
                } else {
                    swal('Error', 'Error al agregar el registro', 'error').then(() => {
                        setredirect('/admin/exceptions');
                    })
                }

            }
            setloading(false);

        } catch(e) {
            setloading(false);

            console.log(e);
            swal('Error', e.message, 'error');
        }
    }

    const cancel = (e) => {
        setredirect('/admin/exceptions');
    }

    const select_all_comunes = () => {
        
        setdata({...data, comunas: futils.comunas.map(e => e.id)});
    }

    const deleteException = (e) => {
        e.preventDefault();
        swal({
            title: "¿Estas seguro?",
            text: "Estas por eliminar una excepción",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                setloading(true);
                let req = new requests({
                    name: "turns",
                    extraUrl: `/exceptions/${id}`
                })

                try {
                    let q = await req.prepareConfig().delete();
                    setloading(false);

                    if(q.Success) {
                        swal('Excelente', 'Excepción eliminada exitosamente', 'success').then(() => {
                            setredirect('/admin/exceptions');
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e);
                    setredirect('/admin/exceptions');
                }
            }
        })
    }

    return (
        <Main loading={loading} redirect={redirect} permission={{module: "turns", action: "view_matrix"}}>
            <SectionTitle 
                title="Excepciones de turnos"
                breadcrumb={[
                    {name: "Home", redirect: '/admin'}, 
                    {name: "Matriz de turnos", redirect: '/admin/available-turns'},
                    {name: "Excepciones", redirect: '/admin/exceptions'},
                    {name: "Excepcion de turno"}
                ]}
                button={[]}
            />

            <div className="row">
                <div className="col-12">
                    <Card>
                        <div className="card-header bg-dark">
                            <h4 className="m-b-0 text-white">Excepcion de turnos</h4>
                        </div>
                        <CardBody>
                            <form onSubmit={send_exc}>
                                <div className="form-body">
                                    <div className="row p-t-20">
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="date_start">Desde: *</label>
                                                <input type="date" id="date_start" defaultValue={momentjs(data.date_start).tz('Atlantic/Madeira').format('YYYY-MM-DD')} className="form-control" onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="date_end">Hasta: *</label>
                                                <input type="date" id="date_end" defaultValue={momentjs(data.date_end).tz('Atlantic/Madeira').format('YYYY-MM-DD')}  className="form-control" onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="form-group col-2">
                                            <button type="button" onClick={select_all_comunes} className="btn btn-dark">Seleccionar todas las comunas</button>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                {futils &&
                                                    futils?.comunas?.map(v => {
                                                        return (
                                                            <div className="col-2">
                                                                <div className="form-check">
                                                                    <input type="checkbox" onClick={handle_change} checked={data?.comunas?.includes(v.id)} id={`comuna|${v.id}`} className="form-check-input"/>
                                                                    <label className="form-check-label" htmlFor={`comuna|${v.id}`}>{v.comuna}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-5">
                                        <h4 className="col-12 text-uppercase font-weight-bold">Lunes</h4>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="AM|Lu">AM: </label>
                                                <input  type="number" id="AM|Lu" className="form-control" value={data?.Lu?.AM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="PM|Lu">PM: </label>
                                                <input  type="number" id="PM|Lu" className="form-control" value={data?.Lu?.PM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="description|Lu">Descripción: </label>
                                                <input  type="text" id="description|Lu" className="form-control" value={data?.Lu?.description || ""} onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <h4 className="col-12 text-uppercase font-weight-bold">Martes</h4>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="AM|Ma">AM: </label>
                                                <input  type="number" id="AM|Ma" className="form-control" value={data?.Ma?.AM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="PM|Ma">PM: </label>
                                                <input  type="number" id="PM|Ma" className="form-control" value={data?.Ma?.PM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="description|Ma">Descripción: </label>
                                                <input  type="text" id="description|Ma" className="form-control" value={data?.Ma?.description || ""} onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <h4 className="col-12 text-uppercase font-weight-bold">Miercoles</h4>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="AM|Mi">AM: </label>
                                                <input  type="number" id="AM|Mi" className="form-control" value={data?.Mi?.AM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="PM|Mi">PM: </label>
                                                <input  type="number" id="PM|Mi" className="form-control" value={data?.Mi?.PM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="description|Mi">Descripción: </label>
                                                <input  type="text" id="description|Mi" className="form-control" value={data?.Mi?.description || ""} onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <h4 className="col-12 text-uppercase font-weight-bold">Jueves</h4>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="AM|Ju">AM: </label>
                                                <input  type="number" id="AM|Ju" className="form-control" value={data?.Ju?.AM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="PM|Ju">PM: </label>
                                                <input  type="number" id="PM|Ju" className="form-control" value={data?.Ju?.PM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="description|Ju">Descripción: </label>
                                                <input  type="text" id="description|Ju" className="form-control" value={data?.Ju?.description || ""} onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <h4 className="col-12 text-uppercase font-weight-bold">Viernes</h4>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="AM|Vi">AM: </label>
                                                <input  type="number" id="AM|Vi" className="form-control" value={data?.Vi?.AM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="PM|Vi">PM: </label>
                                                <input  type="number" id="PM|Vi" className="form-control" value={data?.Vi?.PM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="description|Vi">Descripción: </label>
                                                <input  type="text" id="description|Vi" className="form-control" value={data?.Vi?.description || ""} onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row mt-2">
                                        <h4 className="col-12 text-uppercase font-weight-bold">Sabado</h4>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="AM|Sa">AM: </label>
                                                <input  type="number" id="AM|Sa" className="form-control" value={data?.Sa?.AM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="PM|Sa">PM: </label>
                                                <input  type="number" id="PM|Sa" className="form-control" value={data?.Sa?.PM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="description|Sa">Descripción: </label>
                                                <input  type="text" id="description|Sa" className="form-control" value={data?.Sa?.description || ""} onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <h4 className="col-12 text-uppercase font-weight-bold">Domingo</h4>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="AM|Do">AM: </label>
                                                <input  type="number" id="AM|Do" className="form-control" value={data?.Do?.AM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="PM|Do">PM: </label>
                                                <input  type="number" id="PM|Do" className="form-control" value={data?.Do?.PM || 0} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="description|Do">Descripción: </label>
                                                <input  type="text" id="description|Do" className="form-control" value={data?.Do?.description || ""} onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="form-actions">
                                    <button type="submit" className="col-2 btn btn-success"> <i className="fa fa-save"></i> Guardar</button>
                                    <button type="button" className="ml-1 btn btn-danger" onClick={deleteException}>Eliminar</button>
                                    <button type="button" className="ml-1 btn btn-info" onClick={cancel}>Cancelar</button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default ExceptionsModify;