import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router-dom';
import swal from 'sweetalert';
import Spinner from '../Admin/UI/Spinner';
import requests from '../requests';
const Turn = () => {
    const [step, setstep] = useState(1);
    const [data, setdata] = useState({});
    const [redirect, setredirect] = useState(false)
    const [futils, setfutils] = useState({})
    const [available_days, setavailable_days] = useState({});
    const [loading, setloading] = useState(false);
    const [products, setproducts] = useState([]);
    const [productList, setproductList] = useState([]);
    const [selectedProducts, setselectedProducts] = useState([]);
    const [turnResponse, setturnResponse] = useState(null)

    let total = 0;

    const selectProduct = (e) => {
        const { value } = e.target;

        if(!value) return;

        // Buscamos el producto
        let product = products.find(e => e.id === value);
        if(product && !selectedProducts.find(e => e.id === value)) {
            let insertProduct = selectedProducts;

            insertProduct.push(product);

            setselectedProducts([...insertProduct])
        }
    }

    const [selectedDay, setselectedDay] = useState({});

    const get_futils = async () => {
        const req = new requests({ name: 'futils' });

        try {
            const q = await req.prepareConfig().get();

            if(q.Data) {
                setfutils(q.Data);
            }
        } catch (e) {
            console.log(e);
            setredirect('/');
        }
    }

    const delete_product = (product) => {
        if(!product) return;

        if(products.availability?.required_in_cart) {
            swal('Error', 'No puedes eliminar un servicio obligatorio para tu comuna', 'error')
            return;
        }

        let products_filtred = [];
        if(selectedProducts.length > 1) {
            products_filtred = selectedProducts.filter(e => e.id !== product.id);
        }

        setselectedProducts([...products_filtred]);
    }

    const get_products = async () => {
        setloading(true);
        const comuna = data.comuna;

        if(!comuna) return;
        let req = new requests({
            name: 'products',
            query: { comuna }
        });

        try {
            let q = await req.prepareConfig();
            req.headers = {};
            q = await req.get();

            setloading(false);
            if(q.Data) {
                setproducts([...q.Data]);
                
                // Seteamos los productos por defecto
                let defaultProducts = [];
                let plist = [];
                for(let p of q.Data) {
                    if(p.type === 'service' && p.availability.required_in_cart) {
                        defaultProducts.push(p);
                    } else {
                        plist.push(p);
                    }
                }
                setproductList([...plist]);
                setselectedProducts([...defaultProducts]);
            } else {
                swal('Error', 'Error al obtener las fechas disponibles. Lo redireccionaremos al Home', 'error').then(() => {
                    setredirect('/')
                })
            }
        } catch (e) {
            console.log("Err: ", e);
            swal('Error', e.message, 'error');
        }
    }

    const get_available_days = async () => {
        setloading(true);
        const comuna = data.comuna;
        const date = new Date();

        if(!comuna) return;
        let req = new requests({ 
            name: 'turns', 
            extraUrl: '/calendar',
            query: {
                comuna,
                year: date.getUTCFullYear(),
                month: date.getUTCMonth() + 1
            }
        })

        try {
            let q = await req.prepareConfig();

            req.headers = {};
    
            q = await req.get();
            setloading(false);
            if(q.Data) {
                setavailable_days({...q.Data});
            } else {
                swal('Error', 'Error al obtener las fechas disponibles. Lo redireccionaremos al Home', 'error').then(() => {
                    setredirect('/')
                })
            }
        } catch (e) {
            console.log("Err: ", e);
            swal('Error', e.message, 'error');
        }
    }

    const handle_change = async (e) => {
        e.preventDefault();

        let { value, name } = e.target;

        if(value !== '-1' && name) {
            let data_push = data;

            switch(name) {
                case 'rut': 
                    // 11111111-1
                    if(value.length === 8 && !value.includes('-')) {
                        value += '-';
                    }
                break;
                default:
            }

            data_push[name] = value;
            setdata({...data_push});
        }
    }

    const next_step = async function () {

        let ok_values = 0;
        for(let i = 0; i < arguments.length; i++) {
            const value = arguments[i];

            if(value === 'rut') {
                const rex = new RegExp(/^([0-9]{1,8})+(-)+([a-z0-9]{1})$/);
                if(!rex.test(data[value])) {
                    swal('Error', 'La sintaxis del RUT debe ser 11111111-x', 'error');
                    return;
                }
            }

            if(data[value]) { ++ok_values }
        }

        if(ok_values === arguments.length) {
            if(step === 1) {
                await get_available_days()
            }else if(step === 2) {
                // Requerimos los productos
                await get_products();
            } else if (step === 3) {
                // Creamos el turno
                await create_turn();
            }
            
            setstep(step + 1);
        } else {
            swal('Error', `Debe completar los campos requeridos para continuar`, 'error');
        }
    }

    const create_turn = async () => {
        // preparamos los productos
        let data_to_send = data;

        data_to_send.products = selectedProducts.map(v => v.id);

        setloading(true);
        const req = new requests({ name: 'turns', extraUrl: '/get_turn', body: data_to_send });

        try {
            let q = await req.prepareConfig();

            req.headers = {};
    
            q = await req.post();
            setloading(false);
            if(q.Data) {
                setturnResponse(q.Data);
            } else {
                swal('Error', 'Error al sacar el turno. Lo redireccionaremos al Home', 'error').then(() => {
                    setredirect('/');
                })
            }
        } catch (e) {
            console.log("Err: ", e);
            swal('Error', e.message, 'error');
        }
    }

    const select_day = (day) => {
        let date = new Date();
        if(day.number > 0 && day.available_turns && !day.available_turns.feriado && day.number >= date.getUTCDate()) {
            setselectedDay({...day})
            let date = Date.UTC(available_days.year, available_days.month.number - 1, day.number, 0,0,0);
            setdata({ ...data, date });
        }
    }


    useEffect(() => {
        get_futils();
        document.body.classList.add('turnsPage');
        setstep(0); // Seteamos el step en 0 por este cartel de desactivacion. 3/2/21
        swal({
            title: "Atención",
            text: "Estamos vacunando según calendario ministerial, para asistir por la vacuna del covid deben agendarse previamente, llamando a los teléfonos 222051750-223416873. Para el resto de vacunas tanto ministeriales como privadas, la atención es por orden de llegada de 9 a 18 hrs de lunes a viernes y los sabados de 9 a 13:45.",
            icon: require('../assets/images_swal/3-feb-2021.jpeg')
        }).then(() => {
            setredirect('/');
        })

        return () => {
            document.body.classList.remove('turnsPage');
        }
    }, [])

    if(loading) {
        return <Spinner />
    } else if(redirect) {
        return <Redirect to={redirect} />
    }

    return (
        <div className="container-fluid maxwidthscreen">
            {/* Header */}
            <header className="row page">
                <a className="logo" href="/">
                    <img src={require('../assets/icons/Biosalud.png')} alt="logo"/>
                </a>
                <div className="flag-head">
                    <h2>Turnos</h2>
                </div>
            </header>
            <section className="row">
                <article className="col-12">
                    <p>Reprehenderit dolore ipsum officia culpa.Aliqua mollit deserunt do deserunt amet anim ad reprehenderit adipisicing quis do laboris id anim. Est et ipsum tempor pariatur excepteur amet cupidatat exercitation. Aliqua magna sint duis veniam nulla non tempor ex ad nostrud quis irure. Minim tempor anim est proident veniam aute et ullamco eiusmod. Enim consequat nisi ex sint pariatur excepteur eu qui irure enim fugiat. Nisi aliquip sint in aliqua sit ut labore id velit pariatur consequat velit. Ullamco dolor incididunt ad deserunt deserunt.</p>
                </article>

            </section>
            <section className="form-turn">

                {/* Secciones moviles */}
                {step === 1 &&
                    <article className="turn-step ">
                        <form>
                            <div class="form-group">
                                <select name="comuna" className="form-control b-radius-custom col-12 col-md-6" id="comuna" value={data.comuna || '-1'} onChange={handle_change}>
                                    <option value="-1">Comuna</option>
                                    {futils?.comunas &&
                                        futils.comunas.map(v => {
                                            return (
                                                <option key={v.id} value={v.id}>{v.comuna}</option>
                                            )
                                        })
                                    }
                                </select>
                                <button type="button" onClick={() => next_step('comuna')} className="custom-biosalud float-right mt-2 mt-md-0 col-12 col-md-3">
                                    Siguiente
                                </button>
                            </div>
                        </form>
                    </article>
                }
                {step === 2 && available_days &&
                    <article className="turn-step calendar">
                        <div className="calendarContainer">
                            <table className="calendar-turns table">
                                <thead>
                                    <tr>
                                        <th className="d-none d-md-table-cell">Lunes</th>
                                        <th className="d-table-cell d-md-none">L</th>
                                        <th className="d-none d-md-table-cell">Martes</th>
                                        <th className="d-table-cell d-md-none">M</th>
                                        <th className="d-none d-md-table-cell">Miercoles</th>
                                        <th className="d-table-cell d-md-none">M</th>
                                        <th className="d-none d-md-table-cell">Jueves</th>
                                        <th className="d-table-cell d-md-none">J</th>
                                        <th className="d-none d-md-table-cell">Viernes</th>
                                        <th className="d-table-cell d-md-none">V</th>
                                        <th className="d-none d-md-table-cell">Sabado</th>
                                        <th className="d-table-cell d-md-none">S</th>
                                        <th className="d-none d-md-table-cell">Domingo</th>
                                        <th className="d-table-cell d-md-none">D</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {available_days?.weeks.map((v,i) => {
                                        return (
                                            <tr key={i}>
                                                {v.map((day) => {
                                                    let classes = ""
                                                    let feriado = false;
                                                    let date = new Date();
                                                    if(!day.available_turns) {
                                                        feriado = true;
                                                        classes += 'feriado';
                                                    } else if(day.available_turns.feriado) {
                                                        feriado = true;
                                                        classes += 'feriado';
                                                    } else if(day.number < date.getUTCDate()) {
                                                        feriado = true;
                                                        classes += 'feriado';
                                                    }
                                                    if(selectedDay.number === day.number) {
                                                        classes += ' selected';
                                                    }
                                                    return (
                                                        <td key={day.number ? day.number : Math.random()}>
                                                            <article className={classes} onClick={() => select_day(day)}>
                                                                <h5>{day.number > 0  ? day.number : ''}</h5>
                                                                <span className="d-none d-md-flex">
                                                                    {!feriado &&
                                                                        <>  
                                                                            <p>AM: <strong>{day.available_turns.AM}</strong></p>
                                                                            <p>PM: <strong>{day.available_turns.PM}</strong></p>

                                                                        </>
                                                                    }
                                                                </span>
                                                            </article>
                                                        </td>
                                                    )
                                                })}
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                            {Object.keys(selectedDay).length > 0 &&
                                <div className="selected-day-turn">
                                        <span>
                                            <h6>Mes: <strong>{available_days.month.name}</strong></h6>
                                            <h6>Dia: <strong>{selectedDay.day} {selectedDay.number}</strong></h6>
                                            <div >
                                                {(selectedDay.available_turns.AM > 0 || selectedDay.available_turns.PM > 0) && <>
                                                    <h6>Turno:</h6>
                                                    <select name="time" onChange={handle_change}>
                                                        <option value="-1">Seleccione...</option>
                                                        {selectedDay.available_turns.AM > 0 &&
                                                            <option value="AM">Mañana</option>
                                                        }
                                                        {selectedDay.available_turns.PM > 0 &&
                                                            <option value="PM">Tarde</option>
                                                        }
                                                    </select>
                                                </>}
                                                {selectedDay.available_turns.AM === 0 && selectedDay.available_turns.PM === 0 &&
                                                    <div className="alert alert-danger">No hay turnos disponibles</div>
                                                }
                                            </div>
                                            {selectedDay.available_turns.description &&
                                                <h6>Descripción: <strong>{selectedDay.available_turns.description}</strong></h6>
                                            }
                                        </span>
                                </div>
                            }
                        </div>
                        <div className="buttons-control">
                            <button type="button" onClick={() => setstep(step - 1)} className="custom-biosalud">
                                Anterior
                            </button>
                            <button type="button" onClick={() => next_step('date','time')} className="custom-biosalud">
                                Siguiente
                            </button>
                        </div>
                    </article>
                }
                {step === 3 &&
                    <article className="turn-step">
                        <form>
                                <div className="row">
                                <div className="form-group mt-2  col-12 col-md-4 b-radius-custom">
                                    <input required maxLength="60" type="text" value={data.name} onChange={handle_change} name="name" className="form-control" placeholder="Nombre"/>
                                </div>
                                <div className="mt-2 form-group col-12 col-md-4 b-radius-custom">
                                    <input required maxLength="60" type="text" value={data.last_name} onChange={handle_change} name="last_name" className="form-control" placeholder="Apellido"/>
                                </div>
                                <div className="form-group mt-2  col-12 col-md-4 b-radius-custom">
                                    <input required maxLength="10" type="text" value={data.rut} onChange={handle_change} name="rut" className="form-control" placeholder="RUT"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mt-2  col-12 col-md-4 b-radius-custom">
                                    <input required type="number" value={data.age} onChange={handle_change} name="age" className="form-control" placeholder="Edad"/>
                                </div>
                                <div className="form-group mt-2  col-12 col-md-8 b-radius-custom">
                                    <input required maxLength="12" type="text" value={data.phone} onChange={handle_change} name="phone" className="form-control" placeholder="Telefono"/>
                                </div>
                                <div className="mt-2 form-group col-12 b-radius-custom">
                                    <input required maxLength="80" type="email" value={data.email} onChange={handle_change} name="email" className="form-control" placeholder="Email"/>
                                </div>
                            </div>
                            <div className="row">
                                <div className="form-group mt-2 col-12 col-md-8 b-radius-custom">
                                    <input required maxLength="100" type="text" value={data.address} onChange={handle_change} name="address" className="form-control" placeholder="Dirección"/>
                                </div>
                                <div className="mt-2 form-group col-12 col-md-2 b-radius-custom">
                                    <input type="text" value={data.piso} onChange={handle_change} name="piso" className="form-control" placeholder="Piso"/>
                                </div>
                                <div className="mt-2 form-group col-12 col-md-2 b-radius-custom">
                                    <input type="text" value={data.dpto} onChange={handle_change} name="dpto" className="form-control" placeholder="Dpto"/>
                                </div>
                            </div>
                            <textarea name="extra_address" value={data.extra_address} onChange={handle_change} className="mt-2 form-control" placeholder="Información Extra"></textarea>
                        </form>
                        <div className="products mt-3">
                            <select onChange={selectProduct} className="form-select " value="-1">
                                <option value="-1">Seleccione...</option>
                                {productList.map(v => {
                                    return ( <option key={v.id} value={v.id}>{v.article} ${v.price}</option> )
                                })
                                }
                            </select>
                            <div className="products-list">
                                {selectedProducts.map(v => {
                                    return (
                                    <article key={v.id}>
                                        <button disabled={v.availability.required_in_cart} onClick={() => delete_product(v)} type="button">X</button>
                                        <h6>{v.article} ${v.price}</h6>
                                    </article>);
                                })

                                }
                            </div>
                        </div>
                        <div className="buttons-control">
                            <button type="button" onClick={() => setstep(step - 1)} className="custom-biosalud">
                                Anterior
                            </button>
                            <button type="button" onClick={() => next_step('age','phone','name','last_name', 'email', 'rut', 'address')} className="custom-biosalud">
                                Confirmar
                            </button>
                        </div>
                    </article>
                }
                {step === 4  && turnResponse &&
                    <>
                    <article className="certificado-emision">
                        <div className="certificado">
                            <div className="row">
                                <div className="col-2">
                                    <img width="200" src={require('../assets/icons/Biosalud.png')} alt="logo"/>
                                </div>
                            </div>
                            <div className="row mt-3">
                                <div className="col-3">
                                    <p>Nombre: {turnResponse.turn.name}</p>
                                </div>
                                <div className="col-3">
                                    <p>Apellido: {turnResponse.turn.last_name}</p>
                                </div>
                                <div className="col-2">
                                    <p>Edad: {turnResponse.turn.age}</p>
                                </div>
                                <div className="col-4">
                                    <p>Correo: {turnResponse.turn.email}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-3">
                                    <p>Dirección: {turnResponse.turn.address}</p>
                                </div>
                                <div className="col-3">
                                    <p>Dpto: {turnResponse.turn.dpto}</p>
                                </div>
                                <div className="col-2">
                                    <p>Piso: {turnResponse.turn.piso}</p>
                                </div>
                                <div className="col-4">
                                    <p>Teléfono: {turnResponse.turn.phone}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <p>Vacunas</p>
                                </div>
                                {turnResponse.turn.products.map(v => {
                                    const length_string = 70;
                                    total += v.price;
                                    let vaccine = "";
                                    let price = `$ ${v.price}`;
                                    vaccine += v.article;

                                    // Insertamos los ...
                                    let points_to_insert = length_string - vaccine.length - price.length - 2; // Descontamos 2 de los espacios.
                                    
                                    console.log(vaccine.length, price.length, points_to_insert, length_string);

                                    // Sumamos el espacio
                                    vaccine += ' ';
                                    
                                    for(let i = 0; i < points_to_insert; i++) {
                                        vaccine += '..';

                                    }

                                    // Sumamos el precio
                                    vaccine += ` ${price}`;

                                    return (
                                        <div className="col-12">
                                            <p>- {vaccine}</p>
                                        </div>
                                    )
                                })}
                                <div className="col-12 mt-2">
                                    <p>- Total .......................................................................................................... $ {total}</p>
                                </div>
                            </div>
                        </div>
                        <form className="buttons-control" action={turnResponse.pay.url} method="POST">
                            <input type="hidden" name="token_ws" value={turnResponse.pay.token} />
                            <button type="button" onClick={() => setstep(step - 1)} className="custom-biosalud">
                                Anterior
                            </button>
                            <button type="submit" className="custom-biosalud" type="submit">Pagar</button>
                        </form>
                    </article>
                    </>
                }
            </section>

            <div className="rigth-fixed-buttons">
                <button><i className="fab fa-whatsapp"></i></button>
            </div>
        </div>
    )
}



export default Turn;