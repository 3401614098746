import React, { useEffect, useState } from 'react';
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import helper from '../helper';
import swal from 'sweetalert'

const ProductsModify = ({ match }) => {
    const [loading, setloading]     = useState(false);
    const [redirect, setredirect]   = useState(null);

    const [futils, setfutils] = useState({});
    const [data, setdata] = useState({});
    const [ id, setId ] = useState(null);

    useEffect(() => {
        document.title = helper.get_title("Productos");

        const { id } = match.params;
        if(id && id !== 'new') {
            setId(id);
            get_product(id);
        } else {
            const new_data = {
                article: "",
                code: "",
                description: "",
                price: 0,
                stock: 0,
                communes: [],
                required_in_cart: false,
                type: 'product'
            }
            setdata(new_data);
        }
        get_futils();
        return () => {
            
        }
    }, []);

    const get_futils = async () => {
        const req = new requests({ name: 'futils' });
        try {
            const q = await req.prepareConfig().get();
            if(q.Data) {
                setfutils(q.Data);
            } 
            // setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }


    const get_product = async (id) => {
        setloading(true);
        const req = new requests({ name: 'products', extraUrl: `/${id}`});

        try {
            let q = req.prepareConfig();
            q.headers = {};
            q = await q.get();
            if(q.Data ) {
                const { article, code, description, availability, stock, price, id, type } = q.Data[0];

                let data = {
                    article,
                    code,
                    description,
                    required_in_cart: availability.required_in_cart,
                    communes: availability.communes,
                    stock,
                    price,
                    id,
                    type
                }
                setdata(data);
            } 
            setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    const handle_change = (e) => {
        const { value, id, checked } = e.target;

        const [ field, row ] = id.split('|');

        let data_to_push = data;
        
        if(row) {
            if(field === 'communes') {
                // Sumamos una comuna
                if(!data_to_push.communes) {
                    data_to_push.communes = [];
                }
                if(checked) {
                    // Agregamos al array
                    data_to_push.communes.push(parseInt(row));
                } else {
                    // Eliminamos del array
                    if(data_to_push.communes.length > 1) {
                        data_to_push.communes = data_to_push.communes.filter(e => e !== parseInt(row));
                    } else {
                        data_to_push.communes = [];
                    }
                }
            } 
        } else {
            if(id === 'required_in_cart') {
                data_to_push[field] = !!checked;
                data_to_push.communes = [];
            }else{
                data_to_push[field] = value;
            }
        }

        setdata({ ...data_to_push });
    }

    const send_exc = async (e) => {
        e.preventDefault();
        setloading(true);

        let req = new requests({ name: 'products', body: data });
        try {
            if(id) {
                // Editamos
                req.extraUrl = `/${id}`;

                const q = await req.prepareConfig().put();
                if(q.Success) {
                    swal('Excelente', 'Registro modificado correctamente', 'success').then(() => {
                        setredirect('/admin/products');
                    })
                } else {
                    swal('Error', 'Error al modificar el registro', 'error').then(() => {
                        setredirect('/admin/products');
                    })
                }

            } else {
                // Agregamos nuevo
                const q = await req.prepareConfig().post();
                if(q.Success) {
                    swal('Excelente', 'Registro agregado correctamente', 'success').then(() => {
                        setredirect('/admin/products');
                    })
                } else {
                    swal('Error', 'Error al agregar el registro', 'error').then(() => {
                        setredirect('/admin/products');
                    })
                }

            }
            setloading(false);

        } catch(e) {
            setloading(false);

            console.log(e);
            swal('Error', e.message, 'error');
        }
    }

    const select_all_comunes = () => {
        setdata({ ...data, communes: futils.comunas.map(e => e.id) });
    }

    const cancel = (e) => {
        setredirect('/admin/products');
    }

    const deleteException = (e) => {
        e.preventDefault();
        swal({
            title: "¿Estas seguro?",
            text: "Estas por eliminar un producto",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (ok) => {
            if(ok) {
                setloading(true);
                let req = new requests({
                    name: "products",
                    extraUrl: `/${id}`
                })

                try {
                    let q = await req.prepareConfig().delete();
                    setloading(false);

                    if(q.Success) {
                        swal('Excelente', 'Excepción eliminada exitosamente', 'success').then(() => {
                            setredirect('/admin/products');
                        })
                    } else {
                        swal('Error', q.Message, 'error')
                    }
                } catch (e) {
                    console.error('Error: ',e);
                    setredirect('/admin/products');
                }
            }
        })
    }

    return (
        <Main loading={loading} redirect={redirect}>
            <SectionTitle 
                title="Productos"
                breadcrumb={[
                    {name: "Home", redirect: '/admin'}, 
                    {name: "Productos", redirect: '/admin/products'},
                    {name: "Producto"}
                ]}
                button={[]}
            />

            <div className="row">
                <div className="col-12">
                    <Card>
                        <div className="card-header bg-dark">
                            <h4 className="m-b-0 text-white">{data.id ? `Producto - ID: ${data.id}` : `Nuevo producto`}</h4>
                        </div>
                        <CardBody>
                            <form onSubmit={send_exc}>
                                <div className="form-body">
                                    <div className="row p-t-20">
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="article">Articulo: </label>
                                                <input type="text" id="article" defaultValue={data.article} className="form-control" onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="code">Código: </label>
                                                <input type="text" id="code" defaultValue={data.code}  className="form-control" onChange={handle_change}/>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-md-8">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="description">Descripción: </label>
                                                <input  type="text" id="description" className="form-control" value={data.description} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="price">Precio: </label>
                                                <input  type="number" id="price" className="form-control" value={data.price} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-1">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="stock">Stock: </label>
                                                <input  type="number" id="stock" disabled={data.type !== 'product'} className="form-control" value={data.stock} onChange={handle_change}/>
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label className="control-label" htmlFor="type">Tipo: </label>
                                                <select className="form-control custom-select" defaultValue={data.type} id="type" onChange={handle_change}>
                                                    <option value="product">Producto</option>
                                                    <option value="service">Servicio</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <h4 className="col-2 text-uppercase font-weight-bold">Disponibilidad</h4>
                                        <div className="col-10">
                                            <div className="form-check">
                                                <input type="checkbox" onClick={handle_change} checked={data?.required_in_cart} id="required_in_cart" className="form-check-input"/>
                                                <label className="form-check-label" htmlFor="required_in_cart">Siempre requerido</label>
                                            </div>
                                        </div>
                                        <div className="form-group col-2">
                                            <button disabled={!data?.required_in_cart} type="button" onClick={select_all_comunes} className="btn btn-dark">Seleccionar todas las comunas</button>
                                        </div>
                                        <div className="col-12">
                                            <div className="row">
                                                {futils &&
                                                    futils?.comunas?.map(v => {
                                                        return (
                                                            <div className="col-2">
                                                                <div className="form-check">
                                                                    <input type="checkbox" disabled={!data?.required_in_cart} onClick={handle_change} checked={data?.communes?.includes(v.id)} id={`communes|${v.id}`} className="form-check-input"/>
                                                                    <label className="form-check-label" htmlFor={`communes|${v.id}`}>{v.comuna}</label>
                                                                </div>
                                                            </div>
                                                        )
                                                    })
                                                }

                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="form-actions mt-4">
                                    {helper.checkPermission('products', 'modify') &&
                                    <button type="submit" className="col-2 btn btn-success"> <i className="fa fa-save"></i> Guardar</button>
                                    }
                                    {helper.checkPermission('products', 'delete') &&
                                    <button type="button" className="ml-1 btn btn-danger" onClick={deleteException}>Eliminar</button>
                                    }
                                    <button type="button" className="ml-1 btn btn-info" onClick={cancel}>Cancelar</button>
                                </div>
                            </form>
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default ProductsModify;