import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap';

const ModalRM = (props) => {
    const { children, title, open, className } = props;

    return (
        <Modal isOpen={open} className={className}>
            <ModalHeader>{title}</ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    )
}

export default ModalRM;
