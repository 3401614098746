import React, { useEffect, useState } from 'react';
import Main from '../Layouts/Main';
import SectionTitle from '../UI/SectionTitle';
import Card from '../UI/Card';
import CardBody from '../UI/CardBody';
import requests from '../../requests';
import momentjs from 'moment-timezone';
import helper from '../helper';

const Exceptions = () => {
    const [loading, setloading]     = useState(false);
    const [redirect, setredirect]   = useState(null);

    const [data, setdata] = useState([]);
    const [not_more_registers, setnot_more_registers] = useState(false);
    const [search_params, set_search_params] = useState({ limit: 50, offset: 0 });

    useEffect(() => {
        document.title = helper.get_title("Excepciones");

        get_exceptions();
        return () => {
            
        }
    }, [])

    const get_exceptions = async () => {
        setloading(true);
        const req = new requests({ name: 'turns', extraUrl: '/exceptions', query : search_params});

        try {
            const q = await req.prepareConfig().get();
            if(q.Data && q.Data.length > 0) {
                setdata([...data,...q.Data]);
                set_search_params({ ...search_params, offset: q.Data.length });
            } else {
                setnot_more_registers(true);
            }
            setloading(false);
        } catch (e) {
            setredirect('/admin');
            console.log(e);
        }
    }

    const go_to_exception = (id) => {

        if(id) {
            setredirect(`/admin/exceptions/${id}`);
        }
    }

    return (
        <Main loading={loading} redirect={redirect} permission={{module: "turns", action: "view_matrix"}}>
            <SectionTitle 
                title="Excepciones de turnos"
                breadcrumb={[
                    {name: "Home", redirect: '/admin'}, 
                    {name: "Matriz de turnos", redirect: '/admin/available-turns'},
                    {name: "Excepciones"}
                ]}
                button={[
                    { 
                        name: "Nueva excepción", 
                        icon: "ti-plus", 
                        onClick: () => { setredirect('/admin/exceptions/new') }
                    }
                ]}
            />

            <div className="row">
                <div className="col-12">
                    <Card>
                        <div className="card-header bg-dark">
                            <h4 className="m-b-0 text-white">Excepciones de turnos</h4>
                        </div>
                        <CardBody>
                            <table className="table table-hover table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th>Desde</th>
                                        <th>Hasta</th>
                                        <th>Comunas</th>
                                        <th>Creado el</th>
                                        <th>Ver</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map(v => {
                                        return (
                                            <tr key={v.id}>
                                                <td>{momentjs(v.date_start).tz('Atlantic/Madeira').format("DD/MM/YYYY")}</td>
                                                <td>{momentjs(v.date_end).tz('Atlantic/Madeira').format("DD/MM/YYYY")}</td>
                                                <td>{v.comunas.length}</td>
                                                <td>{momentjs(v.createdAt).format("DD/MM/YYYY hh:mm:ss")}</td>
                                                <td>
                                                    <button type="button" className="btn btn-outline-dark btn-xs" onClick={() => go_to_exception(v.id)}><i className="ti-eye"></i></button>
                                                </td>
                                            </tr>
                                        )
                                    })
                                    }
                                </tbody>
                            </table>
                            <button type="button" className="btn btn-info float-right col-2" onClick={get_exceptions}>Ver Mas</button>
                            {not_more_registers &&
                            <div className="row">
                                <div className="col-6 alert alert-danger">No hay mas registros para mostrar</div>
                            </div>
                            }
                        </CardBody>
                    </Card>
                </div>
            </div>
        </Main>
    )
}

export default Exceptions;